import React from "react";
import { Helmet } from "react-helmet";
import brand from "api/dummy/brand";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { PasswordRecoveryForm } from "components";
import { recover } from "utils/users";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loading from "components/Loading";
import styles from "../../../components/Forms/user-jss";

const errorsMap = {
  INVALID_TOKEN: "token is invalid"
};

class PasswordRecovery extends React.Component {
  submitForm = async (values) => {
    const { token } = this.props;
    const { password } = values.toJS();
    if (!token) {
      throw new Error("token not provided");
    }
    try {
      await recover(token, password);
      return "password changed successfully";
    } catch (ex) {
      throw new Error(
        ex?.response?.status < 500
          ? errorsMap[ex.response?.data?.status?.[0]] || "unexpected error"
          : "unexpected error"
      );
    }
  };

  render() {
    const title = brand.name + " - Recover Password";
    const description = brand.desc;
    const { classes, loggedIn, orgId, history } = this.props;
    if (loggedIn) {
      if (orgId) return <Redirect to="/" />;
      return <Loading />;
    }
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <div className={classes.userFormWrap}>
            <PasswordRecoveryForm
              onSubmit={(values) => this.submitForm(values)}
              onSubmitSuccess={() => history.push("/login")}
            />
          </div>
        </div>
      </div>
    );
  }
}

PasswordRecovery.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  orgId: PropTypes.number
};

PasswordRecovery.defaultProps = {
  orgId: undefined
};

const mapStateToProps = state => ({
  loggedIn: state.getIn(["users", "user", "loggedIn"]),
  orgId: state.getIn(["users", "user", "info", "organization_id"]),
});

const ConnectedComponent = connect(mapStateToProps)(PasswordRecovery);

export default withStyles(styles)(ConnectedComponent);
