import React from "react";
import { Helmet } from "react-helmet";
import brand from "api/dummy/brand";
import { Route } from "react-router-dom";
import { ErrorWrap } from "components";

const title = brand.name + " - Page Not Found";
const description = brand.desc;

function NotFound() {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
        staticContext.status = 404; // eslint-disable-line
        }
        return (
          <div>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="twitter:title" content={title} />
              <meta property="twitter:description" content={description} />
            </Helmet>
            <ErrorWrap title="404" desc={["Oops, Page Not Found :(", "or you don't have enough permissions"]} />
          </div>
        );
      }}
    />
  );
}

export default NotFound;
