import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import UrlUtils from "utils/url";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import messageStyles from "styles/components/Messages.module.scss";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import Popup from "components/Popup/Popup";
import EnhancedDataTable from "components/Tables/EnhancedDataTable";
import { connect } from "react-redux";
import { capitalizeFirstLetter } from "utils/strings";
import { getCurrencyFormatter } from "utils/currency";

const styles = {
  productImage: {
    maxWidth: 88
  },
  chip: {
    margin: "8px 0 8px auto",
    color: "#FFF"
  },
};

class ProductsPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      products: [],
      rowsPerPage: 10,
      page: 0,
      loading: true,
      filters: {},
      availableFilters: undefined
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  componentDidUpdate(prevProps) {
    const {
      getAddedProducts,
      getAllProducts,
    } = this.props;
    const {
      getAddedProducts: oldGetAddedProducts,
      getAllProducts: oldGetAllProducts,
    } = prevProps;
    const { showingAllProducts } = this.state;
    if (
      (!showingAllProducts && getAddedProducts !== oldGetAddedProducts)
      || (showingAllProducts && getAllProducts !== oldGetAllProducts)
    ) {
      this.getProducts(true);
    }
  }

  getColumns = () => {
    const {
      classes,
      getProductLink,
      addButtonLabel,
      removeButtonLabel,
      showExtraColumns,
      brandsMap,
      currency,
      removeProduct,
      addProduct,
    } = this.props;
    const { availableFilters } = this.state;
    const { prices = {} } = availableFilters || {};
    const formatMoney = getCurrencyFormatter(currency);
    return [
      {
        name: "image_url",
        label: "Image",
        options: {
          filter: false,
          renderValue: (value, row) => (
            <div className={classes.productImage}>
              <img src={UrlUtils.getProperImageUrl(value)} alt={row.name} />
            </div>
          ),
        },
      },
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          renderValue: (value, row) =>
            (getProductLink ? (
              <Link to={getProductLink(row)}>{value}</Link>
            ) : (
              value
            )),
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          renderValue: (value, row) => (
            <>
              <p>{value}</p>
              {Array.isArray(row.tags) && (
                <p>
                  {row.tags
                    .slice(0, 3)
                    .map((tag) => tag.alias || tag.name)
                    .join(", ")}
                </p>
              )}
            </>
          ),
        },
      },
      {
        name: "tags",
        label: "Tags",
        options: {
          display: "excluded",
          filter: true,
          filterType: "multiselect",
          filterOptions: {
            names: availableFilters?.tags || [],
            renderValue: (tag) => tag.alias || tag.name
          },
          customFilterListOptions: {
            render: (tag) => tag.alias || tag.name
          }
        }
      },
      {
        name: "brand_id",
        label: "Brand",
        options: {
          display: showExtraColumns || "excluded",
          filter: true,
          renderValue: (value) => brandsMap[value]?.name || value,
          filterOptions: {
            names: Object.keys(brandsMap),
            renderValue: (value) => brandsMap[value]?.name || value,
          },
          customFilterListOptions: {
            render: (value) => brandsMap[value]?.name || value
          }
        },
      },
      {
        name: "prices",
        label: "Price",
        options: {
          filter: true,
          renderValue: (value = {}) => (
            <>
              {Number.isFinite(value.min_price) && (
                formatMoney(value.min_price)
              )}
              {Boolean(
                Number.isFinite(value.max_price)
                  && value.min_price < value.max_price
              ) && (
                <>
                  &nbsp;-&nbsp;
                  {formatMoney(value.max_price)}
                </>
              )}
            </>
          ),
          filterType: "Slider",
          filterOptions: {
            names: [prices.min_price, prices.max_price],
            renderValue: formatMoney,
            fullWidth: true
          }
        },
      },
      {
        name: "quantity",
        label: "Availability",
        options: {
          display: showExtraColumns || "excluded",
          filter: false,
          renderValue: (value) => (
            <Chip
              label={value ? "In stock" : "Out of Stock"}
              className={classNames(
                classes.chip,
                value ? messageStyles.bgSuccess : messageStyles.bgError
              )}
            />
          ),
        },
      },
      {
        name: "added",
        title:
          capitalizeFirstLetter(addButtonLabel)
          + "/"
          + capitalizeFirstLetter(removeButtonLabel),
        options: {
          filter: false,
          display: Boolean(addProduct || removeProduct) || "excluded",
          renderValue: (value, row) => (
            <Chip
              label={value ? removeButtonLabel : addButtonLabel}
              onClick={() => {
                if (value) this.handleRemoveProduct(row.id, row.name);
                else this.handleAddProduct(row.id);
              }}
              className={classNames(
                classes.chip,
                value ? messageStyles.bgError : messageStyles.bgSuccess
              )}
            />
          ),
        },
      },
    ];
  };

  getProducts = async (resetRows = true) => {
    this.setState({ loading: true, ...(resetRows ? { products: [] } : {}) });
    const {
      getAddedProducts,
      getAllProducts
    } = this.props;
    const { page, rowsPerPage, searchText, showingAllProducts, filters } = this.state;
    const getProducts = showingAllProducts ? getAllProducts : getAddedProducts;
    const start = page * rowsPerPage;
    const productsPromise = getProducts(
      {
        ...filters,
        name: searchText,
        start,
        count: rowsPerPage
      }
    );
    this.getFilters();
    const { products, total } = await productsPromise;
    this.setState({ loading: false, products, total });
  };

  getFilters = async () => {
    const { getAllFilters, getAddedFilters } = this.props;
    let { availableFilters } = this.state;
    const { filters, searchText, showingAllProducts, page } = this.state;
    const getFilters = showingAllProducts ? getAllFilters : getAddedFilters;
    const isFiltered = searchText || Object.values(filters).some((filter) =>
      (Array.isArray(filter) ? filter.length : filter)
    );
    if (getFilters && !page && (!availableFilters || !isFiltered)) {
      availableFilters = await getFilters(filters);
      this.setState({ availableFilters });
    }
  };

  handleSearchTextChange = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText, page: 0 }, this.getProducts);
  };

  handleAddProduct = async (productId) => {
    const { addProduct } = this.props;
    if (!addProduct) return;
    try {
      await addProduct(productId);
    } finally {
      this.getProducts(false);
    }
  };

  handleRemoveProduct = async (productId, productName) => {
    const { removeProduct } = this.props;
    if (!removeProduct) return;
    try {
      await removeProduct(productId, productName);
    } finally {
      this.getProducts(false);
    }
  };

  handleChangePage = (_, page) => {
    this.setState({ page }, this.getProducts);
  };

  handleChangeRowsPerPage = ({ target: { value } }) => {
    this.setState({ rowsPerPage: value, page: 0 }, this.getProducts);
  };

  showHideAllProducts = (show) => {
    this.setState({
      showingAllProducts: show,
      searchText: "",
      products: [],
      rowsPerPage: 10,
      page: 0,
      filters: {},
    }, this.getProducts);
  };

  showAllProducts = () => this.showHideAllProducts(true);

  hideAllProducts = () => this.showHideAllProducts(false);

  render() {
    const {
      addedProductsTitle,
      allProductsTitle,
      getAllProducts,
      classes,
      getTableOptions,
    } = this.props;
    const {
      products,
      total,
      page,
      rowsPerPage,
      showingAllProducts,
      loading,
      searchText,
      availableFilters
    } = this.state;

    const popupProps = {
      open: true,
      title: "Add Product",
      fullWidthfullScreen: true,
      maxWidth: "xl",
      onClose: this.hideAllProducts,
      showCloseButton: true,
    };

    const Wrapper = showingAllProducts
      ? Popup
      : React.Fragment;

    const wrapperProps = showingAllProducts ? popupProps : {};

    const addButton = getAllProducts && !showingAllProducts ? (
      <Tooltip title="Add" placement="bottom">
        <IconButton onClick={this.showAllProducts} color="primary">
          <AddIcon />
        </IconButton>
      </Tooltip>
    ) : null;

    const options = {
      filterType: "dropdown",
      responsive: "vertical",
      filter: !!availableFilters,
      search: true,
      sort: false,
      print: true,
      count: total,
      rowsPerPage,
      page,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: loading
            ? "Loading ..."
            : "Sorry, no matching records found",
        },
      },
      serverSide: true,
      confirmFilters: true,
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
        <div style={{ marginTop: "40px" }}>
          <Button variant="contained" onClick={applyNewFilters}>
            Apply Filters
          </Button>
        </div>
      ),
      onConfirmedFiltersChange: ({
        prices: [minPrice, maxPrice] = [],
        brand_id: brandId,
        tags = []
      }) => {
        this.setState(
          {
            filters: {
              minPrice,
              maxPrice,
              brand_id: brandId,
              tags: tags.map((tag) => tag.id),
            },
            page: 0,
          },
          this.getProducts
        );
      },
      onChangePage: (pageIndex) => {
        this.setState({ page: pageIndex }, this.getProducts);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        this.setState(
          { rowsPerPage: numberOfRows, page: 0 },
          this.getProducts
        );
      },
      searchText,
      onSearchChange: (newSearchText) => {
        this.setState({ searchText: newSearchText }, this.getProducts);
      },
      customToolbar: () => addButton,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true
        }
      },
      ...getTableOptions(this.getProducts),
    };
    return (
      <Wrapper {...wrapperProps}>
        <EnhancedDataTable
          title={showingAllProducts ? allProductsTitle : addedProductsTitle}
          data={products}
          columns={this.getColumns()}
          options={options}
          className={classes.tableLong}
        />
      </Wrapper>
    );
  }
}

ProductsPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  getAllProducts: PropTypes.func,
  allProductsTitle: PropTypes.node,
  addedProductsTitle: PropTypes.node.isRequired,
  getAddedProducts: PropTypes.func.isRequired,
  addProduct: PropTypes.func,
  removeProduct: PropTypes.func,
  getProductLink: PropTypes.func,
  addedProductsFilters: PropTypes.object,
  allProductsFilters: PropTypes.object,
  getAddedFilters: PropTypes.func,
  getAllFilters: PropTypes.func,
  showExtraColumns: PropTypes.bool,
  brandsMap: PropTypes.object,
  currency: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.node,
  removeButtonLabel: PropTypes.node,
  getTableOptions: PropTypes.func,
};

ProductsPicker.defaultProps = {
  getAllProducts: undefined,
  allProductsTitle: null,
  addProduct: undefined,
  removeProduct: undefined,
  getProductLink: undefined,
  addedProductsFilters: {},
  allProductsFilters: {},
  getAddedFilters: undefined,
  getAllFilters: undefined,
  showExtraColumns: false,
  brandsMap: {},
  addButtonLabel: "add",
  removeButtonLabel: "remove",
  getTableOptions: () => ({}),
};

const ReduxedProductsPicker = connect(
  (state) => ({
    currency: state.getIn([
      "cachedData",
      "cache",
      "orgData",
      "value",
      "currency",
    ]),
  }),
  null,
  null,
  { forwardRef: true }
)(ProductsPicker);

export default withStyles(styles)(ReduxedProductsPicker);
