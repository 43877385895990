const imageTypes = [
  { label: "LOGO_PRIMARY", id: 1, text: "Logo", hidden: false },
  { label: "LOGO_DARK", id: 11, text: "Logo dark", hidden: false },
  { label: "PRODUCT_DEFAULT", id: 91, text: "Product default", hidden: false },
  { label: "BANNER_TOP", id: 101, text: "Banner top", hidden: false },
  { label: "BANNER_LOWER", id: 111, text: "Banner lower", hidden: false },
  { label: "BANNER_FEATURED", id: 121, text: "Banner featured", hidden: false },
  { label: "SECTION_IMAGE_1", id: 201, text: "Section 1", hidden: false },
  { label: "SECTION_IMAGE_2", id: 202, text: "Section 2", hidden: false },
  { label: "SECTION_IMAGE_3", id: 203, text: "Section 3", hidden: false },
  { label: "SECTION_IMAGE_4", id: 204, text: "Section 4", hidden: false },
  { label: "FAVICON", id: 30, text: "Fav icon", hidden: false },
  { label: "COVER", id: 40, text: "Cover", hidden: false },
  { label: "SEO_DEFAULT", id: 50, text: "SEO Default", hidden: false },
  { label: "SLIDER_HEADER", id: 300, text: "Slider header", multiple: true, hidden: false },
  { label: "SLIDER_IMAGE_1", id: 301, text: "Slider 1", multiple: true, hidden: false },
  { label: "SLIDER_IMAGE_2", id: 302, text: "Slider 2", multiple: true, hidden: false },
  { label: "SLIDER_IMAGE_3", id: 303, text: "Slider 3", multiple: true, hidden: false },
  { label: "SLIDER_IMAGE_4", id: 304, text: "Slider 4", multiple: true, hidden: false },
  { label: "SLIDER_MOBILE_1", id: 321, text: "Mobile slider 1", multiple: true, hidden: false },
  { label: "SLIDER_MOBILE_2", id: 322, text: "Mobile slider 2", multiple: true, hidden: false },
  { label: "SLIDER_MOBILE_3", id: 323, text: "Mobile slider 3", multiple: true, hidden: false },
  { label: "SLIDER_MOBILE_4", id: 324, text: "Mobile slider 4", multiple: true, hidden: false },
  { label: "TAG_LOGO", id: 400, text: "tag logo", multiple: true, hidden: true },
  { label: "TAG_BANNER", id: 410, text: "tag banner", multiple: true, hidden: true },
  { label: "360_SCENE", id: 360, text: "360 image", multiple: true, hidden: true }
];

const productImagePriority = [{ id: 0, name: "cover" }, { id: 1, name: "normal" }];
const productImageTypes = [{ id: 7, name: "product" }, { id: 1, name: "variant" }];

const imageTypesMap = Object.freeze(
  imageTypes.reduce((object, type) => {
    const { id, label } = type;
    // eslint-disable-next-line no-multi-assign, no-param-reassign
    object[label] = object[id] = type;
    return object;
  }, {})
);

export { imageTypesMap, productImagePriority, productImageTypes, imageTypes as default };
