const statusMap = Object.freeze({
  CLIENT_CONFIRMED: "Pending payment choice",
  CLIENT_CANCELLED: "Cancelled",
  FINALIZED: "New",
  STORE_CONFIRMED: "Confirmed",
  STORE_PREPARED: "Prepared",
  DISPATCHED: "Dispatched",
  DELIVERED: "Delivered",
  STORE_CANCELLED: "Rejected",
  RETURNED: "Returned",
  PAID: "Paid",
  UNPAID: "Unpaid",
  FAILED: "Failed",
  PROCESSING: "Processing",
  NEW: "New",
  RECEIVED: "Received",
  REJECTED: "Rejected",
  CONFIRMED: "Confirmed"
});

const editableStatuses = new Set([
  "FINALIZED",
  "STORE_CONFIRMED",
  "STORE_PREPARED",
  "DISPATCHED",
  "DELIVERED"
]);

const achievableStatuses = [
  "FINALIZED",
  "STORE_CONFIRMED",
  "STORE_PREPARED",
  "DISPATCHED",
  "DELIVERED",
  "STORE_CANCELLED"
];

const finishedOrders = [
  "FINALIZED",
  "STORE_CONFIRMED",
  "STORE_PREPARED",
  "DISPATCHED",
  "DELIVERED",
];

const unFinishedOrders = [
  "CLIENT_CANCELLED",
  "RETURNED",
  "DISCARDED",
  "CLIENT_CONFIRMED",
  "STORE_CANCELLED",
  "NEW"
];

export default { statusMap, editableStatuses, achievableStatuses, finishedOrders, unFinishedOrders };
