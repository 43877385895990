import {
  call,
  put,
  fork,
  select,
  takeLatest
} from "redux-saga/effects";
import { Set } from "immutable";
import { eventChannel } from "redux-saga";
import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  SAVE_USER_TOKEN,
  SAVE_USER_INFO,
  SAVE_USER_ROLES,
  LOGOUT_REQUEST,
  LOGOUT,
  ADD_NOTIFICATION
} from "../actions/actionConstants";
import { setConfirmNavigation } from "../actions/UiActions";
import { saveUser, login, logout, removeToken } from "../utils/users";
import Data from "../utils/data";

function* authorize(action) {
  const { resolve, reject, formValues: { email, password, remember_me: rememberMe } } = action;
  try {
    const { token, ...userInfo } = yield call(login, email, password, rememberMe);
    yield put({ type: LOGIN_SUCCESS });
    yield put({ type: SAVE_USER_TOKEN, token });
    yield put({ type: SAVE_USER_INFO, userInfo });
    yield put({ type: SAVE_USER_ROLES, userRoles: Set(userInfo.roles) });
    saveUser(token);
    resolve(`welcome ${userInfo.name}`);
  } catch (error) {
    yield put({ type: LOGIN_ERROR, error });
    reject(error.response?.data);
  }
}

function* handleLogout(_, forced = false) {
  if (!forced) {
    const confirmNavigation = yield select(state => state.getIn(["ui", "confirmNavigation"]));
    const canLogout = !confirmNavigation || window.confirm("changes not saved, leave anyway?");
    if (!canLogout) return;
    try {
      yield call(logout);
    } catch (ex) {
      if (ex.response?.status === 401) return;
      if (!window.confirm("an error occured!\nwould you like to force logout?")) return;
    }
  }
  yield call(removeToken);
  yield put(setConfirmNavigation(false));
  yield put({ type: LOGOUT });
}

function* saveProfile() {
  const loggedIn = yield select(state => state.getIn(["users", "user", "loggedIn"]));
  if (loggedIn) {
    try {
      const userInfo = yield call(Data.getUserInfo);
      yield put({ type: SAVE_USER_INFO, userInfo });
      yield put({ type: SAVE_USER_ROLES, userRoles: Set(userInfo.roles) });
    } catch {
      // logout if info api failed?
    }
  }
}

const createUnauthorizedChannel = () => {
  const unauthorizedChannel = eventChannel(emitter => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          emitter(401);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  });
  return unauthorizedChannel;
};

function* dispatchLogout() {
  const loggedIn = yield select(state => state.getIn(["users", "user", "loggedIn"]));
  if (loggedIn) {
    yield call(handleLogout, {}, true);
    yield put({ type: ADD_NOTIFICATION, message: "You were signed out, please sign-in again." });
  }
}

function* handleUnauthorized() {
  const unauthorizedChannel = yield call(createUnauthorizedChannel);
  yield takeLatest(unauthorizedChannel, dispatchLogout);
}

export default function* loginSaga() {
  yield fork(handleUnauthorized);
  yield fork(saveProfile);
  yield takeLatest(LOGIN_REQUEST, authorize);
  yield takeLatest(LOGOUT_REQUEST, handleLogout);
}
