import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  dialogContent: {
    overflowY: "auto",
    display: "flex",
    alignItems: "stretch",
    overflowX: "auto",
  },
  dialogPaper: {
    overflow: "hidden",
  }
});

function Popup({
  open,
  fullWidth,
  fullScreen,
  maxWidth,
  onClose,
  showCloseButton,
  title,
  children,
  actions,
  classes,
  ...rest
}) {
  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
      }}
      {...rest}
    >
      <DialogTitle id="dialog-title">
        {title}
        {Boolean(showCloseButton) && (
          <IconButton onClick={onClose} size="small" style={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      {Boolean(actions) && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}

Popup.propTypes = {
  open: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false]),
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  title: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.object.isRequired,
};

Popup.defaultProps = {
  open: false,
  maxWidth: "sm",
  fullWidth: false,
  fullScreen: false,
  onClose: () => {},
  showCloseButton: false,
  title: null,
  actions: null,
};

export default withStyles(styles)(Popup);
