import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "containers/Pages/Standalone/NotFoundDedicated";
import NotificationsProvider from "components/NotificationsProvider";
import ConfirmationProvider from "components/ConfirmationProvider/ConfirmationProvider";
import ForgotPasswordDedicated from "containers/Pages/Standalone/ForgotPasswordDedicated";
import PasswordRecoveryDedicated from "containers/Pages/Standalone/PasswordRecoveryDedicated";
import Auth from "./Auth";
import Application from "./Application";
import LoginDedicated from "../Pages/Standalone/LoginDedicated";
import ThemeWrapper, { AppContext } from "./ThemeWrapper";
import ProtectedRoute from "../../utils/ProtectedRoute";
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class App extends React.Component {
  render() {
    return (
      <ThemeWrapper>
        <NotificationsProvider />
        <ConfirmationProvider />
        <AppContext.Consumer>
          {changeMode => (
            <Switch>
              <Route
                path="/forgot-password"
                exact
                component={ForgotPasswordDedicated}
              />
              <Route
                path="/password-recovery"
                exact
                component={PasswordRecoveryDedicated}
              />
              <Route
                path="/login"
                exact
                component={LoginDedicated}
              />
              <ProtectedRoute
                path="/"
                render={props => (
                  <Application {...props} changeMode={changeMode} />
                )}
                skipRoles
              />
              <Route component={Auth} />
              <Route component={NotFound} />
            </Switch>
          )}
        </AppContext.Consumer>
      </ThemeWrapper>
    );
  }
}

export default App;
