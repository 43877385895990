import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import ShoppingCart from "@material-ui/icons/ShoppingCartOutlined";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Style from "@material-ui/icons/Style";
import Typography from "@material-ui/core/Typography";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import cyan from "@material-ui/core/colors/cyan";
import pink from "@material-ui/core/colors/pink";
import orange from "@material-ui/core/colors/orange";
import colorfull from "api/palette/colorfull";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend
} from "recharts";
import { RequestGroup } from "utils/data";
import styles from "./widget-jss";
import PapperBlock from "../PapperBlock/PapperBlock";
import OrderUtils from "../../utils/orders";
import "styles/components/vendors/rechart/styles.css";

const requestedStatusColors = {
  DELIVERED: colorfull[6],
  STORE_CONFIRMED: colorfull[0],
  STORE_CANCELLED: colorfull[3],
  CLIENT_CANCELLED: colorfull[2],
  RETURNED: colorfull[4],
};

const colorsPie = [purple[500], blue[500], cyan[500], pink[500], orange[500]];

class SalesChartWidget extends PureComponent {
  requestGroup = new RequestGroup();

  constructor(props) {
    super(props);
    this.state = {
      basketItems: [],
      orderStats: [],
      totalOrderCounts: {}
    };
  }

  componentDidMount() {
    this.getStats();
  }

  getStats = async () => {
    const basketItems = await this.requestGroup.getStatisticsCarts();

    this.setState({
      basketItems: basketItems.slice(0, 5).map((item) => ({
        name: item.variant_name,
        value: item.quantity,
      })),
    });
    const totalOrderCounts = {};
    const requestedStatuses = Object.keys(requestedStatusColors);
    requestedStatuses.forEach(status => {
      totalOrderCounts[status] = 0;
    });
    const orderCounts = await this.requestGroup.getStatisticsOrders(
      requestedStatuses,
      6,
      "COUNT"
    );
    const orderStats = orderCounts.map(entry => {
      Object.entries(entry).forEach(([key, value]) => {
        if (key !== "month") {
          totalOrderCounts[key] += value;
        }
      });
      return {
        ...entry,
        name: entry.month.substring(0, 3)
      };
    });
    this.setState({ orderStats, totalOrderCounts });
  };

  render() {
    const { classes } = this.props;
    const { basketItems, orderStats, totalOrderCounts } = this.state;
    return (
      <PapperBlock
        whiteBg
        noMargin
        title="Orders Stats"
        icon="ios-stats-outline"
        desc=""
      >
        <Grid container spacing={2}>
          <Grid item md={8} xs={12} className={classes.chartContainer}>
            <ul className={classes.bigResume}>
              {Object.entries(requestedStatusColors).map(([status, color]) => (
                <li key={status}>
                  <Avatar
                    className={classNames(classes.avatar, classes.blackAvatar)}
                    style={{
                      backgroundColor: color,
                    }}
                  >
                    <Style />
                  </Avatar>
                  <Typography variant="h6">
                    <span className={classes.blackText} style={{ color }}>
                      {totalOrderCounts[status]}
                    </span>
                    <Typography className={classes.legendLabel}>{OrderUtils.statusMap[status]}</Typography>
                  </Typography>
                </li>
              ))}
            </ul>
            <div className={classes.chartWrap}>
              <div className={classes.chartFluid}>
                <ResponsiveContainer>
                  <BarChart data={orderStats}>
                    <XAxis dataKey="name" tickLine={false} />
                    <YAxis
                      axisLine={false}
                      tickSize={3}
                      tickLine={false}
                      tick={{ stroke: "none" }}
                    />
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <CartesianAxis />
                    <Tooltip />
                    {Object.entries(requestedStatusColors).map(([status, color]) => (
                      <Bar dataKey={status} name={OrderUtils.statusMap[status]} fill={color} />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.smallTitle} variant="button">
              <ShoppingCart className={classes.leftIcon} />
              Top Cart Items
            </Typography>
            <Divider className={classes.divider} />
            <Grid container className={classes.secondaryWrap}>
              <PieChart width={300} height={300}>
                <Pie
                  data={basketItems}
                  cx={150}
                  cy={100}
                  dataKey="value"
                  innerRadius={40}
                  outerRadius={80}
                  fill="#FFFFFF"
                  paddingAngle={5}
                  label
                >
                  {basketItems.map((entry, index) => (
                    <Cell
                      key={index.toString()}
                      fill={colorsPie[index % colorsPie.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  iconType="circle"
                  verticalALign="bottom"
                  iconSize={10}
                />
              </PieChart>
            </Grid>
          </Grid>
        </Grid>
      </PapperBlock>
    );
  }
}

SalesChartWidget.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SalesChartWidget);
