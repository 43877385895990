import Loadable from "react-loadable";
import Loading from "components/Loading";

export const BlankPage = Loadable({
  loader: () => import("./Pages/BlankPage"),
  loading: Loading,
});
export const DashboardPage = Loadable({
  loader: () => import("./Pages/Dashboard"),
  loading: Loading,
});
export const Organization = Loadable({
  loader: () => import("./Pages/Forms/Organization"),
  loading: Loading
});
export const OrganizationDomains = Loadable({
  loader: () => import("./Pages/Table/OrganizationDomains"),
  loading: Loading
});
export const CreateBrand = Loadable({
  loader: () => import("./Pages/Forms/CreateBrand"),
  loading: Loading
});
export const UpdateBrand = Loadable({
  loader: () => import("./Pages/Forms/UpdateBrand"),
  loading: Loading
});
export const OrganizationImage = Loadable({
  loader: () => import("./Pages/Forms/OrganizationImage"),
  loading: Loading
});
export const CreateShop = Loadable({
  loader: () => import("./Pages/Forms/CreateShop"),
  loading: Loading
});
export const UpdateShop = Loadable({
  loader: () => import("./Pages/Forms/UpdateShop"),
  loading: Loading
});
export const CreateCategory = Loadable({
  loader: () => import("./Pages/Forms/CreateCategory"),
  loading: Loading
});
export const UpdateCategory = Loadable({
  loader: () => import("./Pages/Forms/UpdateCategory"),
  loading: Loading
});
export const TagsTree = Loadable({
  loader: () => import("./Pages/Forms/TagsTree"),
  loading: Loading
});
export const ShopSections = Loadable({
  loader: () => import("./Pages/Listing/ShopSections"),
  loading: Loading
});
export const Shop360Bulk = Loadable({
  loader: () => import("./Pages/Forms/Shop360Bulk"),
  loading: Loading
});
export const Shop360Editor = Loadable({
  loader: () => import("./Pages/Standalone/Shop360Editor"),
  loading: Loading
});
export const OrganizationInfo = Loadable({
  loader: () => import("./Pages/Forms/OrganizationInfo"),
  loading: Loading
});
export const GenerateSiteMap = Loadable({
  loader: () => import("./Pages/Forms/GenerateSiteMap"),
  loading: Loading
});
export const CreateUser = Loadable({
  loader: () => import("./Pages/Forms/CreateUser"),
  loading: Loading
});
export const Customers = Loadable({
  loader: () => import("./Pages/Table/Customers"),
  loading: Loading
});
export const Employees = Loadable({
  loader: () => import("./Pages/Table/Employees"),
  loading: Loading
});
export const SubscribedUsers = Loadable({
  loader: () => import("./Pages/Table/SubscribedUsers"),
  loading: Loading
});
export const ImportProducts = Loadable({
  loader: () => import("./Pages/Forms/ImportProducts"),
  loading: Loading
});
export const ExportProducts = Loadable({
  loader: () => import("./Pages/Standalone/ExportProducts"),
  loading: Loading
});
export const Brands = Loadable({
  loader: () => import("./Pages/Listing/Brands"),
  loading: Loading
});
export const Tags = Loadable({
  loader: () => import("./Pages/Listing/Tags"),
  loading: Loading
});
export const Collections = Loadable({
  loader: () => import("./Pages/Table/Collections"),
  loading: Loading
});
export const Products = Loadable({
  loader: () => import("./Pages/Table/Products"),
  loading: Loading
});
export const Shops = Loadable({
  loader: () => import("./Pages/Listing/Shops"),
  loading: Loading
});
export const SubAreas = Loadable({
  loader: () => import("./Pages/Table/SubAreas"),
  loading: Loading
});
export const ManageOrders = Loadable({
  loader: () => import("./Pages/Table/ManageOrders"),
  loading: Loading
});
export const ReturnRequests = Loadable({
  loader: () => import("./Pages/Table/ReturnRequests"),
  loading: Loading
});
export const SingleReturnRequest = Loadable({
  loader: () => import("./Pages/Standalone/SingleReturnRequest"),
  loading: Loading
});
export const FindReturnRequest = Loadable({
  loader: () => import("./Pages/Forms/FindReturnRequest"),
  loading: Loading
});
export const SingleOrder = Loadable({
  loader: () => import("./Pages/Standalone/SingleOrder"),
  loading: Loading
});
export const MetaOrder = Loadable({
  loader: () => import("./Pages/Standalone/MetaOrder"),
  loading: Loading
});
export const Products360 = Loadable({
  loader: () => import("./Pages/Table/Products360"),
  loading: Loading
});
export const CreateTag = Loadable({
  loader: () => import("./Pages/Forms/CreateTag"),
  loading: Loading
});
export const UpdateTag = Loadable({
  loader: () => import("./Pages/Forms/UpdateTag"),
  loading: Loading
});
export const TagProducts = Loadable({
  loader: () => import("./Pages/Table/TagProducts"),
  loading: Loading
});
export const CreateProduct = Loadable({
  loader: () => import("./Pages/Forms/CreateProduct"),
  loading: Loading
});
export const UpdateProduct = Loadable({
  loader: () => import("./Pages/Forms/UpdateProduct"),
  loading: Loading
});
export const RelatedProducts = Loadable({
  loader: () => import("./Pages/Table/RelatedProducts"),
  loading: Loading
});
export const RelatedCollections = Loadable({
  loader: () => import("./Pages/Table/RelatedCollections"),
  loading: Loading
});
export const CreateCollection = Loadable({
  loader: () => import("./Pages/Forms/CreateCollection"),
  loading: Loading
});
export const UpdateCollection = Loadable({
  loader: () => import("./Pages/Forms/UpdateCollection"),
  loading: Loading
});
export const CollectionProducts = Loadable({
  loader: () => import("./Pages/Table/CollectionProducts"),
  loading: Loading
});
export const ProductsImages = Loadable({
  loader: () => import("./Pages/Forms/ProductsImages"),
  loading: Loading
});
export const CreateProductFeature = Loadable({
  loader: () => import("./Pages/Forms/ProductFeature"),
  loading: Loading
});
export const ProductFeatures = Loadable({
  loader: () => import("./Pages/Table/ProductFeatures"),
  loading: Loading
});
export const UpdateProductFeature = Loadable({
  loader: () => import("./Pages/Forms/UpdateProductFeature"),
  loading: Loading
});
export const ThemesClasses = Loadable({
  loader: () => import("./Pages/Table/ThemesClasses"),
  loading: Loading
});
export const CreateTheme = Loadable({
  loader: () => import("./Pages/Forms/CreateTheme"),
  loading: Loading
});
export const Themes = Loadable({
  loader: () => import("./Pages/Listing/Themes"),
  loading: Loading
});
export const UpdateTheme = Loadable({
  loader: () => import("./Pages/Forms/UpdateTheme"),
  loading: Loading
});
export const OrganizationClasses = Loadable({
  loader: () => import("./Pages/Forms/OrganizationClasses"),
  loading: Loading
});
export const OrganizationThemes = Loadable({
  loader: () => import("./Pages/Forms/OrganizationThemes"),
  loading: Loading
});
export const CreatePromotion = Loadable({
  loader: () => import("./Pages/Forms/CreatePromotion"),
  loading: Loading
});
export const UpdatePromotion = Loadable({
  loader: () => import("./Pages/Forms/UpdatePromotion"),
  loading: Loading
});
export const ListPromotions = Loadable({
  loader: () => import("./Pages/Table/ListPromotions"),
  loading: Loading
});
export const Reviews = Loadable({
  loader: () => import("./Pages/Table/Reviews"),
  loading: Loading
});
export const Table = Loadable({
  loader: () => import("./Pages/Table/BasicTable"),
  loading: Loading,
});
export const Login = Loadable({
  loader: () => import("./Pages/Users/Login"),
  loading: Loading,
});
export const LoginDedicated = Loadable({
  loader: () => import("./Pages/Standalone/LoginDedicated"),
  loading: Loading,
});
export const Register = Loadable({
  loader: () => import("./Pages/Users/Register"),
  loading: Loading,
});
export const ForgotPassword = Loadable({
  loader: () => import("./Pages/Users/ForgotPassword"),
  loading: Loading,
});
export const NotFound = Loadable({
  loader: () => import("./NotFound/NotFound"),
  loading: Loading,
});
export const NotFoundDedicated = Loadable({
  loader: () => import("./Pages/Standalone/NotFoundDedicated"),
  loading: Loading,
});
export const Error = Loadable({
  loader: () => import("./Pages/Error"),
  loading: Loading,
});
export const Maintenance = Loadable({
  loader: () => import("./Pages/Maintenance"),
  loading: Loading,
});
export const ComingSoon = Loadable({
  loader: () => import("./Pages/ComingSoon"),
  loading: Loading,
});
export const Parent = Loadable({
  loader: () => import("./Parent"),
  loading: Loading,
});
export const Categories = Loadable({
  loader: () => import("./Pages/Listing/Categories"),
  loading: Loading
});
export const VideoChat = Loadable({
  loader: () => import("./Pages/Table/VideoChat"),
  loading: Loading
});
export const ProductVariants = Loadable({
  loader: () => import("./Pages/Table/ProductVariants"),
  loading: Loading
});
export const UpdateVariant = Loadable({
  loader: () => import("./Pages/Forms/UpdateVariant"),
  loading: Loading
});
export const UpdateUser = Loadable({
  loader: () => import("./Pages/Forms/UpdateUser"),
  loading: Loading
});
export const PointsConfiguration = Loadable({
  loader: () => import("./Pages/Points/PointsConfiguration/PointsConfiguration"),
  loading: Loading,
});
export const ControlPoints = Loadable({
  loader: () => import("./Pages/Points/ControlPoints/ControlPoints"),
  loading: Loading,
});
export const CreateControlPoint = Loadable({
  loader: () => import("./Pages/Points/ControlPoints/CreateControlPoint/CreateControlPoint"),
  loading: Loading,
});
export const UpdateControlPoint = Loadable({
  loader: () => import("./Pages/Points/ControlPoints/UpdateControlPoint/UpdateControlPoint"),
  loading: Loading,
});
