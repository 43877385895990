import axios from "axios";
import memoizeOne from "memoize-one/dist/memoize-one";
import urlUtils, { paths, save360Cookie } from "./url";

const setUserToken = (userToken) => {
  axios.defaults.headers["User-Token"] = userToken;
  save360Cookie(userToken);
};

export function loadUser() {
  const token = localStorage.getItem("userToken");
  setUserToken(token);
  return {
    token,
    info: {},
    loggedIn: Boolean(token)
  };
}

export function saveUser(token) {
  localStorage.setItem("userToken", token);
  const d = new Date();
  d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));// 30 days
  const expires = "expires=" + d.toUTCString();
  document.cookie = `X-User-Token=${token};${expires};path=/;`;
}

export async function login(email, password, rememberMe) {
  const response = await axios.post(urlUtils.getProperApiUrl("user/login"), {
    email,
    password,
    employee: true,
    remember_me: rememberMe
  });
  setUserToken(response.data.token);
  return response.data;
}

export async function logout() {
  const { data } = await axios.post(urlUtils.getProperApiUrl("user/logout"));
  return data;
}

export function removeToken() {
  delete axios.defaults.headers["User-Token"];
  localStorage.removeItem("userToken");
}

export async function recover(token, password) {
  const { data } = await axios.post(
    urlUtils.getProperApiUrl("/user/recover"),
    { employee: true, token, password }
  );
  return data;
}

export const roles = Object.freeze({
  NASNAV_ADMIN: { id: "NASNAV_ADMIN", name: "Nasnav Admin", requireShop: false },
  ORGANIZATION_ADMIN: { id: "ORGANIZATION_ADMIN", name: "Organization Admin", requireShop: false },
  ORGANIZATION_MANAGER: { id: "ORGANIZATION_MANAGER", name: "Organization Manager", requireShop: false },
  ORGANIZATION_EMPLOYEE: { id: "ORGANIZATION_EMPLOYEE", name: "Organization Employee", requireShop: false },
  // STORE_ADMIN: { id: "STORE_ADMIN", name: "Store Admin", requireShop: true },
  STORE_MANAGER: { id: "STORE_MANAGER", name: "Store Manager", requireShop: true },
  STORE_EMPLOYEE: { id: "STORE_EMPLOYEE", name: "Store Employee", requireShop: true }
});

export const rolesHierarchy = {
  NASNAV_ADMIN: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // can't create store roles as he can't list stores
    // roles.STORE_ADMIN,
    // roles.STORE_MANAGER,
    // roles.STORE_EMPLOYEE,
  ],
  ORGANIZATION_ADMIN: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  // STORE_ADMIN: [roles.STORE_ADMIN, roles.STORE_MANAGER, roles.STORE_EMPLOYEE],
};

export const permissions = Object.freeze({
  [paths.dashboardPage]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  [paths.organization]: [roles.NASNAV_ADMIN],
  [paths.manageDomains]: [roles.NASNAV_ADMIN],
  [paths.createCategory]: [roles.NASNAV_ADMIN],
  [paths.categories]: [roles.NASNAV_ADMIN],
  [paths.themesClasses]: [roles.NASNAV_ADMIN],
  [paths.createTheme]: [roles.NASNAV_ADMIN],
  [paths.themes]: [roles.NASNAV_ADMIN],
  [paths.organizationsClasses]: [roles.NASNAV_ADMIN],
  [paths.createBrand]: [roles.ORGANIZATION_ADMIN],
  [paths.brands]: [roles.ORGANIZATION_ADMIN],
  [paths.createCollection]: [roles.ORGANIZATION_ADMIN],
  [paths.collections]: [roles.ORGANIZATION_ADMIN],
  [paths.createUser]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    // roles.STORE_ADMIN,
  ],
  [paths.updateUser]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    // roles.STORE_ADMIN,
  ],
  [paths.employees]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    // roles.STORE_ADMIN,
  ],
  [paths.customers]: [roles.NASNAV_ADMIN, roles.ORGANIZATION_ADMIN],
  [paths.subscribedUsers]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.manageOrders]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.metaOrders]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  [paths.returnRequests]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.findReturnRequest]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.createProductFeature]: [roles.ORGANIZATION_ADMIN],
  [paths.productFeatures]: [roles.ORGANIZATION_ADMIN],
  [paths.createProduct]: [roles.ORGANIZATION_ADMIN],
  [paths.products]: [roles.ORGANIZATION_ADMIN],
  [paths.exportProducts]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.STORE_MANAGER,
  ],
  [paths.importProducts]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.STORE_MANAGER,
  ],
  [paths.productsImages]: [roles.ORGANIZATION_ADMIN],
  [paths.createPromotion]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.reviews]: [roles.ORGANIZATION_ADMIN],
  [paths.listPromotions]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.createShop]: [roles.ORGANIZATION_MANAGER],
  [paths.shops]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.subAreas]: [roles.ORGANIZATION_ADMIN],
  [paths.createTag]: [roles.ORGANIZATION_ADMIN],
  [paths.tags]: [roles.ORGANIZATION_ADMIN],
  [paths.tagsTree]: [roles.ORGANIZATION_ADMIN],
  [paths.organizationImage]: [roles.ORGANIZATION_ADMIN],
  [paths.organizationInfo]: [roles.ORGANIZATION_ADMIN],
  [paths.organizationThemes]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.organizationSiteMap]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  [paths.videoChat]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
  ],
  [paths.updateVariant]: [roles.ORGANIZATION_ADMIN],
  // points
  [paths.pointsConfiguration]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.controlPoints]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.createControlPoints]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updateControlPoint]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
});

export const checkPermissions = memoizeOne((url, userRoles) => {
  const [, ...urlParts] = url.split("/");
  let currentUrl = url.endsWith("/") && url.length > 1 ? url.substring(0, url.length - 1) : url;
  let currentPermissions = null;
  for (let i = urlParts.length; i > -1 && !currentPermissions; i -= 1) {
    const currentPart = urlParts[i];
    if (currentPart) currentUrl = currentUrl.substring(0, currentUrl.length - currentPart.length - 1);
    currentPermissions = permissions[currentUrl]?.map?.(({ id }) => id);
  }
  const res = userRoles.intersect(currentPermissions || []).size > 0;
  return res;
});

export const canSelectShop = memoizeOne((roleIds) =>
  roleIds.some(
    (roleId) =>
      roleId === roles.ORGANIZATION_ADMIN.id
      || roleId === roles.ORGANIZATION_MANAGER.id
  )
);
