const guideSteps = [
  {
    title: "Hi, welcome",
    label: "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
    imgPath: "./images/guide/guide1.jpg",
  },
  {
    title: "Dark and Light Mode",
    label: "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
    imgPath: "./images/guide/guide2.gif",
  },
  // {
  //   title: "Themes and Layout",
  //   label: "Build your template with various themes and layout combination. It easy to adjust following your brand identity",
  //   imgPath: "./images/guide/guide3.gif",
  // },
  {
    title: "Search Components and Pages",
    label: "Find any page quicker",
    imgPath: "./images/guide/guide4.gif",
  },
  // {
  //   title: "Code Preview",
  //   label: "Get source code easier without open files. Just click showcode button then You will see what a magic behind. It is available for Layout, Form Buttons, UI Components, Chart and Maps category",
  //   imgPath: "./images/guide/guide5.gif",
  // },
];

export default guideSteps;
