import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset } from "redux-form";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Notification } from "components";

class Progress extends React.Component {
  componentWillUnmount() {
    const { cancel } = this.props;
    cancel();
  }

  handleUploadClose = (ev, reason) => {
    const { cancel } = this.props;
    if (reason !== "clickaway") {
      cancel();
    }
  };

  render() {
    const { Component, progress, cancel, ...props } = this.props;
    return (
      <>
        {Boolean(progress) && (
          <Notification
            key="progress"
            open={!!progress}
            message={(
              <LinearProgress
                style={{ width: "30vw" }}
                variant="determinate"
                value={progress}
              />
            )}
            onClose={this.handleUploadClose}
          />
        )}
        <Component
          {...props}
          cancel={cancel}
        />
      </>
    );
  }
}

Progress.propTypes = {
  Component: PropTypes.elementType.isRequired,
  progress: PropTypes.number,
  cancel: PropTypes.func
};

Progress.defaultProps = {
  progress: null,
  cancel: () => {}
};

const ReduxProgress = connect((state, ownProps) => ({
  progress: state.getIn(["forms", ownProps.form, "progress"], null),
  cancel: state.getIn(["forms", ownProps.form, "cancel"])
}),
(dispatch, ownProps) => ({
  resetForm: () => dispatch(reset(ownProps.form))
}))(Progress);

export default Form => function (props) {
  return <ReduxProgress Component={Form} {...props} />;
};
