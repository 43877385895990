const isYeshtery = process.env.REACT_APP_BUILD_YESHTERY === "true";

const fallbackBackendUrl = isYeshtery
  ? "https://api-dev.yeshtery.com/v1"
  : "https://backend.nasnav.org";
let baseUrl = fallbackBackendUrl;

const publicApiPrefix = isYeshtery ? "/yeshtery" : "/navbox";

/**
 * Debugging env variables
 */
localStorage.setItem("Env", JSON.stringify(process.env));

// set "REACT_APP_RESTAPI=url"
// export REACT_APP_RESTAPI=url
console.log("REACT_APP_RESTAPI: " + process.env.REACT_APP_RESTAPI);

if (process.env.REACT_APP_RESTAPI) {
  baseUrl = process.env.REACT_APP_RESTAPI;
  console.log("Checking backend %s: ", baseUrl);
}

const filesStorageUrl = process.env.NODE_ENV === "development"
  ? process.env.REACT_APP_BUILD_YESHTERY === "true"
    ? "https://develop.yeshtery.com/files"
    : "https://develop.nasnav.org/files"
  : "/files";

document.cookie = `backend_url=${baseUrl};path=/;`;

export const save360Cookie = (userToken) => {
  const pannellumCookie = {
    backend_api_url: baseUrl,
    files_api_url: filesStorageUrl,
    user_token: userToken,
  };
  document.cookie = `360=${JSON.stringify(pannellumCookie)}; path=/;`;
};

save360Cookie();

const addSlash = (url, changeCase = true) => {
  const newUrl = url ? changeCase ? url.toLowerCase() : url : "";
  return (
    (newUrl && newUrl.startsWith("/") ? "" : "/") + encodeURI(newUrl.trim())
  );
};

const getProperApiUrl = (url, isPublic = false) => baseUrl + (isPublic ? publicApiPrefix : "") + addSlash(url);

const getProperImageUrl = (url) =>
  url && filesStorageUrl + addSlash(url, false);

const defaultPName = name =>
  name.replace(/[^a-zA-Z0-9]+/g, "-").replace(/^-|-$/g, "").toLowerCase();

/**
 * converts query parameters to an object
 * @param {string} search
 */
const toSearchParams = queryObject =>
  "?"
  + Object.entries(queryObject)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const paths = {
  dashboardPage: "/",
  organization: "/create-update-organization",
  manageDomains: "/manage-domains",
  createCategory: "/create-category",
  updateCategory: "/categories/:categoryId(\\d+)/:categoryName?",
  createBrand: "/create-brand",
  createShop: "/create-shop",
  shopSections: "/shops/:shopId(\\d+)/:shopName/360-images",
  shop360Bulk: "/shops/:shopId(\\d+)/:shopName/360-bulk",
  shop360Editor: "/shops/:shopId(\\d+)/:shopName/360-editor",
  updateShop: "/shops/:shopId(\\d+)/:shopName?",
  shops: "/shops",
  subAreas: "/sub-areas",
  organizationImage: "/organization-image",
  organizationInfo: "/organization-info",
  createUser: "/create-user",
  updateUser: "/employees/:userId(\\d+)/:userName?",
  employees: "/employees",
  customers: "/customers",
  subscribedUsers: "/subscribed-users",
  importProducts: "/import-products",
  exportProducts: "/export-products",
  manageOrders: "/orders",
  singleOrder: "/orders/:orderId(\\d+)",
  metaOrders: "/meta-orders",
  singleMetaOrder: "/meta-orders/:orderId(\\d+)",
  findReturnRequest: "/find-return-request",
  returnRequests: "/return-requests",
  singleReturnRequest: "/return-requests/:returnRequestId(\\d+)",
  createProductFeature: "/create-product-feature",
  productFeatures: "/product-features",
  updateProductFeature: "/product-features/:featureId(\\d+)/:featureName?",
  createTag: "/create-tag",
  updateTag: "/tags/:tagId(\\d+)/:tagName?",
  tagProducts: "/tags/:tagId(\\d+)/:tagName/products",
  tags: "/tags",
  createProduct: "/create-product",
  updateProduct: "/products/:productId(\\d+)/:productName?",
  relatedProducts: "/products/:productId(\\d+)/:productName/related",
  productVariants: "/products/:productId(\\d+)/:productName/variants",
  products: "/products",
  createCollection: "/create-collection",
  updateCollection: "/collections/:collectionId(\\d+)/:collectionName?",
  collectionProducts: "/collections/:collectionId(\\d+)/:collectionName/products",
  relatedCollections: "/collections/:collectionId(\\d+)/:collectionName/related",
  collections: "/collections",
  updateBrand: "/brands/:brandId(\\d+)/:brandName?",
  brands: "/brands",
  tagsTree: "/tags-tree",
  productsImages: "/products-images",
  themesClasses: "/themes-classes",
  createTheme: "/create-theme",
  themes: "/themes",
  UpdateTheme: "/themes/:themeId/:themeName?",
  organizationsClasses: "/organizations-classes",
  organizationThemes: "/organization-themes",
  organizationSiteMap: "/organization-site-map",
  createPromotion: "/create-promotion",
  updatePromotion: "/promotions/:promoId(\\d+)/:promoName?",
  listPromotions: "/promotions",
  reviews: "/reviews",
  categories: "/categories",
  videoChat: "/video-chat",
  updateVariant: "/products/:productId(\\d+)/:productName/variants/:variantId(\\d+)/:variantName",
  // points
  pointsConfiguration: "/points-configuration",
  controlPoints: "/control-points",
  createControlPoints: "/create-control-points",
  updateControlPoint: "/update-control-points/:tierId?/:tierName?",
};

export { paths };

export default {
  getProperApiUrl,
  getProperImageUrl,
  toSearchParams,
  defaultPName,
};
