import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Field, reduxForm } from "redux-form/immutable";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AllInclusive from "@material-ui/icons/AllInclusive";
import Brightness5 from "@material-ui/icons/Brightness5";
import People from "@material-ui/icons/People";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import Hidden from "@material-ui/core/Hidden";
import brand from "api/dummy/brand";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import { TextFieldRedux, CheckboxRedux } from "./ReduxFormMUI";
import styles from "./user-jss";
import { ContentDivider } from "../Divider";
const logo = "./images/nasnav-logo-full.png";

// validation functions
const required = value => (value == null ? "Required" : undefined);
const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? "Invalid email"
  : undefined);
const number = value => (value && Number.isNaN(Number(value)) ? "Must be a number" : undefined);

const LinkBtn = React.forwardRef((props, ref) =>
  // eslint-disable-next-line react/prop-types
  <NavLink to={props.to} {...props} innerRef={ref} />
);

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  render() {
    const { classes, handleSubmit, pristine, submitting, deco } = this.props;
    const { showPassword } = this.state;
    return (
      <>
        <Hidden mdUp>
          <NavLink to="/" className={classNames(classes.brand, classes.outer)}>
            <img src={logo} alt={brand.name} />
          </NavLink>
        </Hidden>
        <Paper className={classNames(classes.paperWrap, deco && classes.petal)}>
          <Hidden smDown>
            <div className={classes.topBar}>
              <NavLink to="/" className={classes.brand}>
                <img src={logo} alt={brand.name} />
              </NavLink>
            </div>
          </Hidden>
          <Typography variant="h4" className={classes.title} gutterBottom>
            Sign In
          </Typography>
          {/* <Typography
            variant="caption"
            className={classes.subtitle}
            gutterBottom
            align="center"
          >
            Lorem ipsum dolor sit amet
          </Typography> */}
          <section className={classes.socmedLogin} style={{ display: "none" }}>
            <div className={classes.btnArea}>
              <Button
                variant="outlined"
                size="small"
                className={classes.redBtn}
                type="button"
              >
                <AllInclusive
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                Socmed 1
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.blueBtn}
                type="button"
              >
                <Brightness5
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                Socmed 2
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.cyanBtn}
                type="button"
              >
                <People
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                Socmed 3
              </Button>
            </div>
            <ContentDivider content="Or sign in with email" />
          </section>
          <section className={classes.formWrap}>
            <form onSubmit={handleSubmit}>
              <div>
                <FormControl className={classes.formControl}>
                  <Field
                    name="email"
                    component={TextFieldRedux}
                    placeholder="Your Email"
                    label="Your Email"
                    required
                    validate={[required, email]}
                    className={classes.field}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.formControl}>
                  <Field
                    name="password"
                    component={TextFieldRedux}
                    type={showPassword ? "text" : "password"}
                    label="Your Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    required
                    validate={required}
                    className={classes.field}
                  />
                </FormControl>
              </div>
              <div className={classes.optArea}>
                <FormControlLabel
                  className={classes.label}
                  control={<Field name="remember_me" component={CheckboxRedux} />}
                  label="Remember"
                />
                <Button
                  size="small"
                  component={LinkBtn}
                  to="/forgot-password"
                  className={classes.buttonLink}
                >
                  Can&apos;t access your account?
                </Button>
              </div>
              <div className={classes.btnArea}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Continue
                  <ArrowForward
                    className={classNames(classes.rightIcon, classes.iconSmall)}
                    disabled={submitting || pristine}
                  />
                </Button>
              </div>
            </form>
          </section>
        </Paper>
      </>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired
};

const LoginFormReduxed = reduxForm({
  form: "immutableExample",
  enableReinitialize: true
})(LoginForm);

const reducerLogin = "login";
const reducerUi = "ui";
const FormInit = connect(
  state => ({
    initialValues: state.getIn([reducerLogin, "usersLogin"]),
    deco: state.getIn([reducerUi, "decoration"])
  }),
  dispatch => ({
    onSubmitSuccess: message =>
      dispatch({ type: ADD_NOTIFICATION, message }),
    onSubmitFail: (...[, , submitError]) => {
      switch (submitError?.error) {
        case "U$LOG$0002":
          dispatch({
            type: ADD_NOTIFICATION,
            message: "Invalid email or password",
          });
          break;
        case "U$LOG$0003":
          dispatch({
            type: ADD_NOTIFICATION,
            message: "Sorry, the account needed to be activated",
          });
          break;
        case "U$LOG$0004":
          dispatch({
            type: ADD_NOTIFICATION,
            message: "Sorry, the account has been suspended",
          });
          break;
        default:
          dispatch({
            type: ADD_NOTIFICATION,
            message: submitError?.message || "unexpected error" });
          break;
      }
    },
  })
)(LoginFormReduxed);

export default withStyles(styles)(FormInit);
