import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PapperBlock from "components/PapperBlock/PapperBlock";
import ReduxForm from "./ReduxForm";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  fieldsWrapper: {
    padding: "30px 30px 0"
  },
  buttonsWrapper: {
    padding: "0 30px 30px"
  },
  inlineWrap: {
    display: "flex",
    flexDirection: "row"
  },
  buttonInit: {
    margin: theme.spacing(4),
    textAlign: "center"
  }
});

class PaperForm extends Component {
  render() {
    const {
      classes,
      children,
      title,
      description,
      container: Container,
      md,
      ...rest
    } = this.props;
    return (
      <div>
        <Container
          title={title}
          icon="ios-list-box-outline"
          desc={description}
        >
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            direction="row"
            justify="center"
          >
            <Grid item xs={12} md={md}>
              <Paper className={classes.root}>
                <ReduxForm
                  {...rest}
                  buttonsContainer={({ className, ...props }) => (
                    <div
                      {...props}
                      className={`${className} ${classes.buttonsWrapper}`}
                    />
                  )}
                >
                  <div className={classes.fieldsWrapper}>{children}</div>
                </ReduxForm>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

PaperForm.propTypes = {
  form: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hideButtons: PropTypes.bool,
  /* eslint-disable react/no-unused-prop-types */
  blockOnDirty: PropTypes.bool,
  blockOnSubmitting: PropTypes.bool,
  /* eslint-enable react/no-unused-prop-types */
  md: PropTypes.number,
  container: PropTypes.element
};

PaperForm.defaultProps = {
  hideButtons: false,
  blockOnDirty: true,
  blockOnSubmitting: true,
  md: 6,
  container: PapperBlock
};

export default withStyles(styles)(PaperForm);
