import React from "react";
import { Helmet } from "react-helmet";
import brand from "api/dummy/brand";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { LoginForm } from "components";
import styles from "components/Forms/user-jss";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loading from "components/Loading";
import { LOGIN_REQUEST } from "../../../actions/actionConstants";

class Login extends React.Component {
  submitForm(values) {
    const { login } = this.props;
    const valuesObject = values.toJS();
    return login(valuesObject);
  }

  render() {
    const title = brand.name + " - Login";
    const description = brand.desc;
    const { classes, loggedIn, orgId } = this.props;
    if (loggedIn) {
      if (orgId) return <Redirect to="/" />;
      return <Loading />;
    }
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <div className={classes.userFormWrap}>
            <LoginForm onSubmit={(values) => this.submitForm(values)} />
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  orgId: PropTypes.number
};

Login.defaultProps = {
  orgId: undefined
};

const mapStateToProps = state => ({
  loggedIn: state.getIn(["users", "user", "loggedIn"]),
  orgId: state.getIn(["users", "user", "info", "organization_id"]),
});

const mapDispatchToProps = dispatch => ({
  login: formValues =>
    new Promise((resolve, reject) =>
      dispatch({ type: LOGIN_REQUEST, formValues, resolve, reject })
    )
});

const ReduxLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withStyles(styles)(ReduxLogin);
