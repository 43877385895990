import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import avatarApi from "api/images/avatars";
import classNames from "classnames";
import { EmptyData } from "components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import messageStyles from "styles/components/Messages.module.scss";
import { RequestGroup } from "../../utils/data";
import OrderUtils from "../../utils/orders";
import PapperBlock from "../PapperBlock/PapperBlock";
import styles from "./widget-jss";

class LatestOrders extends React.Component {
  requestGroup = new RequestGroup();

  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loadingOrders: false,
      rowsPerPage: 10,
      page: 0,
      total: 0,
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  componentWillUnmount() {
    this.requestGroup.cancelAndReuse();
  }

  handleChangePage = (event, pageIndex) => {
    this.setState({ page: pageIndex }, this.getOrders);
  };

  handleChangeRowsPerPage = (event) => {
    const numberOfRows = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: numberOfRows, page: 0 }, this.getOrders);
  };

  getOrders = () => {
    const { page, rowsPerPage } = this.state;
    this.setState({ loadingOrders: true });
    const start = page * rowsPerPage;
    const count = rowsPerPage;
    const created_after = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().replace("T", ":").split(".")[0];
    const created_before = new Date().toISOString().replace("T", ":").split(".")[0];
    this.requestGroup
      .getOrderList({ details_level: 2, start, count, created_after, created_before })
      .then(({ total, orders }) => {
        this.setState({ orders, loadingOrders: false, total });
      })
      .catch(() => {
        this.setState({ loadingOrders: false });
      });
  };

  getStatus = status => {
    switch (status) {
      case "CLIENT_CONFIRMED":
        return messageStyles.bgDefault;
      case "STORE_CONFIRMED":
        return messageStyles.bgInfo;
      case "STORE_PREPARED":
        return messageStyles.bgWarning;
      case "DISPATCHED":
        return messageStyles.bgSuccess;
      default:
        return messageStyles.bgError;
    }
  };

  getpPaymentStatus = status => {
    switch (status) {
      case "UNPAID":
        return messageStyles.bgError;
      case "PAID":
        return messageStyles.bgSuccess;
      default:
        return messageStyles.bgDefault;
    }
  };

  render() {
    const { classes } = this.props;
    const { orders, loadingOrders, page, rowsPerPage, total } = this.state;
    const options = { year: "numeric", month: "short", day: "numeric" };
    return (
      <PapperBlock
        noMargin
        title="Latest Orders"
        icon="ios-share-outline"
        whiteBg
        buttonIcon="md-refresh"
        buttonOnClick={this.getOrders}
        buttonRotate={loadingOrders}
      >
        {orders.length ? (
          <>
            <div className={classes.root}>
              <Table
                className={classNames(classes.tableLong, classes.stripped)}
                padding="default"
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="default">Details</TableCell>
                    <TableCell>Buyer</TableCell>
                    <TableCell align="left">Discount</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Payment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => {
                    const isReferralCode = order?.is_referral_code_applied;
                    const isReferralWallet = order?.is_used_referral_balance;
                    return [
                      <TableRow key={order.order_id}>
                        <TableCell padding="default">
                          <div className={classes.flex}>
                            <div>
                              <Typography variant="caption">
                                <Link to={`/orders/${order.order_id}`}>{order.meta_order_id + "-" + order.order_id}</Link>
                              </Typography>
                              <Typography variant="subtitle1">
                                Branch:&nbsp;
                                {order.shop_name}
                              </Typography>
                              <Typography variant="subtitle2">
                                Quantity:&nbsp;
                                {order.total_quantity}
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.flex}>
                            <Avatar
                              alt={order.user_name || "John Doe"}
                              src={avatarApi[6]}
                              className={classNames(classes.avatar, classes.sm)}
                            />
                            <div>
                              <Typography>
                                {order.user_name || "John Doe"}
                              </Typography>
                              <Typography variant="caption">
                                Purchased date:&nbsp;
                                {new Date(order.creation_date).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell padding="default">
                          <div className={classes.flex}>
                            <div>
                              {order?.discount !== 0 ? (
                                <div>
                                  <Typography variant="subtitle2">
                                    {order.discount}
                                    &nbsp;
                                    {order.currency}
                                  </Typography>
                                  {(isReferralCode || isReferralWallet) && (
                                    <div className={classes.referral}>
                                      {isReferralCode && "Referral code"}
                                      {isReferralWallet && "Referral wallet"}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                "_"
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="button">
                            {order.total}
                            {order.currency}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={OrderUtils.statusMap[order.status]}
                            className={classNames(
                              classes.chip,
                              this.getStatus(order.status)
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={OrderUtils.statusMap[order.payment_status]}
                            className={classNames(
                              classes.chip,
                              this.getpPaymentStatus(order.payment_status)
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ];
                  })}
                </TableBody>
              </Table>
            </div>
            <div className={classes.tablePagination}>
              <TablePagination
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </>
        ) : (
          <EmptyData />
        )}
      </PapperBlock>
    );
  }
}

LatestOrders.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LatestOrders);
