import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

/* LabelRedux */
export function LabelRedux({ input: { value }, ...rest }) {
  return <span {...rest}>{value}</span>;
}

LabelRedux.propTypes = {
  input: PropTypes.object.isRequired
};
/* End */

/* Textfield */
export function TextFieldRedux({ meta: { touched, error }, input, ...rest }) {
  return (
    <TextField
      {...input}
      {...rest}
      error={touched && Boolean(error)}
      helperText={touched && error && error}
    />
  );
}

TextFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* DateTimePicker */
export function DateTimePickerRedux({
  meta: { touched, error },
  input: { value, onChange, onBlur },
  ...rest
}) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        {...rest}
        value={value || null}
        onChange={onChange}
        onClose={() => onBlur()}
        error={touched && Boolean(error)}
        helperText={touched && error && error}
      />
    </MuiPickersUtilsProvider>
  );
}

DateTimePickerRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

DateTimePickerRedux.defaultProps = {
  meta: null,
};
/* End */

/* Select */
export function SelectRedux({
  meta: { touched, error },
  input: { value, ...input },
  children,
  label,
  className,
  multiple,
  renderValue,
  ...rest
}) {
  return (
    <FormControl
      error={touched && Boolean(error)}
      className={className}
      {...rest}
    >
      <InputLabel htmlFor={input.id}>{label}</InputLabel>
      <Select
        multiple={multiple}
        renderValue={renderValue}
        value={multiple ? value?.toJS?.() || value : value}
        {...input}
      >
        {children}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

ToggleButtonGroup.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
};

ToggleButtonGroup.defaultProps = {
  meta: null,
  label: null,
  className: null,
  multiple: false,
  renderValue: undefined,
};
/* End */

export function CheckboxMenuItem({ value, text, selected, ...props }) {
  return (
    <MenuItem key={value} value={value} selected={selected} {...props}>
      <Checkbox checked={selected} />
      <ListItemText primary={text} />
    </MenuItem>
  );
}

CheckboxMenuItem.propTypes = {
  value: PropTypes.any.isRequired,
  text: PropTypes.string,
  selected: PropTypes.bool
};

CheckboxMenuItem.defaultProps = {
  text: null,
  selected: false
};

/* Checkbox */
export function CheckboxRedux({
  meta: { touched, error },
  input,
  label,
  className,
  labelPlacement,
  ...rest
}) {
  return (
    <FormControl
      error={touched && Boolean(error)}
      className={className}
      {...rest}
    >
      <FormControlLabel
        control={(
          <Checkbox
            checked={input.checked}
            {...input}
          />
        )}
        label={label}
        labelPlacement={labelPlacement}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

CheckboxRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  labelPlacement: PropTypes.string
};

CheckboxRedux.defaultProps = {
  meta: null,
  label: null,
  className: null,
  labelPlacement: undefined
};
/* End */

/* Radio */
export function RadioRedux({
  meta: { touched, error },
  input,
  label,
  className,
  ...rest
}) {
  return (
    <FormControl
      error={touched && Boolean(error)}
      className={className}
      {...rest}
    >
      <FormControlLabel
        control={(
          <Radio
            checked={input.checked}
            {...input}
          />
        )}
        label={label}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

RadioRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string
};

RadioRedux.defaultProps = {
  meta: null,
  label: null,
  className: null
};
/* End */

/* Switch */
export function SwitchRedux({ input, ...rest }) {
  return (
    <Switch
      checked={input.value === "" ? false : input.value}
      {...input}
      {...rest}
    />
  );
}

SwitchRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* ToggleButtonGroup */
export function ToggleButtonGroupRedux({
  meta: { touched, error },
  input: { value, onChange, onBlur, ...input },
  children,
  label,
  className,
  groupclassName,
  exclusive,
  orientation,
  ...rest
}) {
  return (
    <FormControl
      component="fieldset"
      error={touched && Boolean(error)}
      className={className}
      {...rest}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <ToggleButtonGroup
        {...input}
        exclusive={exclusive}
        orientation={orientation}
        value={exclusive ? value : value?.toJS?.() || value}
        onChange={(ev, val) => onChange?.(val)}
        onBlur={() => onBlur?.()}
        className={groupclassName}
      >
        {children}
      </ToggleButtonGroup>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

ToggleButtonGroupRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
};

ToggleButtonGroupRedux.defaultProps = {
  meta: null,
  label: null,
  className: null,
  multiple: false,
  renderValue: undefined,
};
/* End */
