import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import brand from "api/dummy/brand";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import formUtils from "utils/form";
import { TextFieldRedux } from "./ReduxFormMUI";
import styles from "./user-jss";
const logo = "./images/nasnav-logo-full.png";

const {
  validation: { required }
} = formUtils;

const passwordsMatch = (value, allValues) => {
  if (value !== allValues.get("password")) {
    return "Passwords dont match";
  }
  return undefined;
};

class PasswordRecoveryForm extends React.Component {
  state = {
    showPassword: false
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      deco,
    } = this.props;
    const { showPassword } = this.state;

    return (
      <Paper className={classNames(classes.paperWrap, deco && classes.petal)}>
        <div className={classes.topBar}>
          <NavLink to="/" className={classes.brand}>
            <img src={logo} alt={brand.name} />
          </NavLink>
        </div>
        <Typography variant="h4" className={classes.title} gutterBottom>
          Recover Password
        </Typography>
        <Typography
          variant="caption"
          className={classes.subtitle}
          gutterBottom
          align="center"
        >
          Enter your new password
        </Typography>
        <section className={classes.formWrap}>
          <form onSubmit={handleSubmit}>
            <div>
              <FormControl className={classes.formControl}>
                <Field
                  name="password"
                  component={TextFieldRedux}
                  type={showPassword ? "text" : "password"}
                  label="New Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  required
                  validate={[required, passwordsMatch]}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <Field
                  name="passwordConfirm"
                  component={TextFieldRedux}
                  type="password"
                  label="Re-type Password"
                  required
                  validate={[required, passwordsMatch]}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div className={classes.btnArea}>
              <Button variant="contained" color="primary" type="submit">
                Change Password
                <ArrowForward
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                  disabled={submitting || pristine}
                />
              </Button>
            </div>
          </form>
        </section>
      </Paper>
    );
  }
}

PasswordRecoveryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const PasswordRecoveryFormReduxed = reduxForm({
  form: "immutableEResetFrm",
  enableReinitialize: true,
})(PasswordRecoveryForm);

const reducer = "ui";
const PasswordRecoveryFormMapped = connect(
  state => ({
    deco: state.getIn([reducer, "decoration"])
  }),
  (dispatch, ownProps) => ({
    onSubmitSuccess: message => {
      dispatch({ type: ADD_NOTIFICATION, message });
      ownProps.onSubmitSuccess(message);
    },
    onSubmitFail: (...[, , submitError]) =>
      submitError && dispatch({ type: ADD_NOTIFICATION, message: submitError?.message })
  })
)(PasswordRecoveryFormReduxed);

PasswordRecoveryFormMapped.propTypes = {
  onSubmitSuccess: PropTypes.func
};

PasswordRecoveryFormMapped.defaultProps = {
  onSubmitSuccess: () => {}
};

export default withStyles(styles)(PasswordRecoveryFormMapped);
