import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import brand from "api/dummy/brand";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import formUtils from "utils/form";
import { TextFieldRedux } from "./ReduxFormMUI";
import styles from "./user-jss";
const logo = "./images/nasnav-logo-full.png";

const {
  validation: { required, email }
} = formUtils;

class ForgotPasswordForm extends React.Component {
  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      deco,
      loading
    } = this.props;
    return (
      <Paper className={classNames(classes.paperWrap, deco && classes.petal)}>
        <div className={classes.topBar}>
          <NavLink to="/" className={classes.brand}>
            <img src={logo} alt={brand.name} />
          </NavLink>
        </div>
        <Typography variant="h4" className={classes.title} gutterBottom>
          Reset Password
        </Typography>
        <Typography variant="caption" className={classes.subtitle} gutterBottom align="center">
          Send reset password link to Your email
        </Typography>
        <section className={classes.formWrap}>
          <form onSubmit={handleSubmit}>
            <div>
              <FormControl key="email" className={classes.formControl}>
                <Field
                  name="email"
                  component={TextFieldRedux}
                  placeholder="Your Email"
                  label="Your Email"
                  required
                  validate={[required, email]}
                  className={classes.field}
                />
              </FormControl>
            </div>
            <div className={classes.btnArea}>
              <Button variant="contained" color="primary" type="submit" disabled={loading}>
                Send Reset Link
                <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
              </Button>
            </div>
          </form>
        </section>
      </Paper>
    );
  }
}

ForgotPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const ResetFormReduxed = reduxForm({
  form: "immutableEResetFrm",
  enableReinitialize: true,
})(ForgotPasswordForm);

const reducer = "ui";
const RegisterFormMapped = connect(
  state => ({
    deco: state.getIn([reducer, "decoration"]),
  }),
  dispatch => ({
    onSubmitSuccess: message =>
      dispatch({ type: ADD_NOTIFICATION, message }),
    onSubmitFail: (...[, , submitError]) =>
      submitError && dispatch({ type: ADD_NOTIFICATION, message: submitError?.message })
  })
)(ResetFormReduxed);

export default withStyles(styles)(RegisterFormMapped);
