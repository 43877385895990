import { call, put, takeLatest } from "redux-saga/effects";
import menuData from "api/ui/menu";
import {
  SAVE_USER_INFO,
  SAVE_FILTERED_MENU,
  SAVE_USER_ROLES
} from "../actions/actionConstants";
import { permissions, checkPermissions } from "../utils/users";

const filterSubMenu = (item, userRoles) => {
  let newItem;
  if (item.child) {
    newItem = {
      ...item,
      // eslint-disable-next-line no-use-before-define
      child: filterMenuRecursive(item.child, userRoles)
    };
  } else if (item.link) {
    newItem = checkPermissions(item.link, userRoles) ? item : null;
  } else {
    newItem = item;
  }
  return newItem;
};

const nonEmptyNodeOrLeaf = (item, nextItem) =>
  Boolean(
    item && ((!item.title || (nextItem && !nextItem.title)) && (!item.child || item.child.length > 0))
  );

const filterMenuRecursive = (menu, userRoles) => {
  const reversedMenu = [...menu].reverse();
  const reversedFilteredMenu = reversedMenu.reduce((filteredMenu, item) => {
    const filteredItem = filterSubMenu(item, userRoles);
    const nextItem = filteredMenu[filteredMenu.length - 1];
    if (nonEmptyNodeOrLeaf(filteredItem, nextItem)) {
      filteredMenu.push(filteredItem);
    }
    return filteredMenu;
  }, []);
  return reversedFilteredMenu.reverse();
};

function* filterMenu(action) {
  const { userRoles } = action;
  const filteredMenu = yield call(filterMenuRecursive, menuData, userRoles);
  yield put({ type: SAVE_FILTERED_MENU, filteredMenu });
}

export default function* uiSaga() {
  yield takeLatest(SAVE_USER_ROLES, filterMenu);
}
