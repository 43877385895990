import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PapperBlock } from "components";
import ProductsPicker from "components/Tables/ProductsPicker";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import ReorderList from "components/ReorderList/ReorderList";
import urlUtils from "utils/url";
import { getSubmitDispatcher } from "actions/ReduxFormActions";
import { Field } from "redux-form/immutable";
import memoizeOne from "memoize-one/dist/memoize-one";
import { RequestGroup } from "../../../utils/data";
import { getPrices } from "../../../utils/Product";
import PaperForm from "../Forms/PaperForm";

const styles = (theme) => ({
  fieldsContainer: {
    marginBottom: 50
  },
  field: {
    width: "30%",
    marginRight: 10,
    verticalAlign: "baseline"
  },
  table: {
    "& > div": {
      overflow: "auto",
    },
    "& table": {
      "& td": {
        wordBreak: "keep-all",
      },
      [theme.breakpoints.down("md")]: {
        "& td": {
          height: 60,
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
});

/*
  It uses npm mui-datatables. It's easy to use, you just describe columns and data collection.
  Checkout full documentation here :
  https://github.com/gregnb/mui-datatables/blob/master/README.md
*/
class CollectionProducts extends React.Component {
  productsRequestGroup = new RequestGroup();

  postRequestGroup = new RequestGroup(false);

  constructor(props) {
    super(props);
    const { collectionId, collectionName } = props.match.params;
    this.state = {
      collection: { id: collectionId, name: collectionName },
      variantsSet: new Set(),
      reordering: false,
      reorderedVariants: [],
    };
  }

  componentDidMount() {
    this.getCollection();
  }

  componentDidUpdate(prevProps) {
    const { orgId, match } = this.props;
    const { collectionId } = match.params;
    const { orgId: prevOrgId, match: prevMatch } = prevProps;
    const { collectionId: prevCollectionId } = prevMatch.params;
    if (orgId !== prevOrgId || collectionId !== prevCollectionId) {
      this.getCollection();
    }
  }

  componentWillUnmount() {
    this.productsRequestGroup.cancelAndReuse("CollectionProducts will unmount");
  }

  onDragHover = memoizeOne((draggedItem, targetIndex) => {
    const { reorderedVariants } = this.state;
    const newOrder = [...reorderedVariants];
    const [draggedVariant] = newOrder.splice(draggedItem.index, 1);
    newOrder.splice(targetIndex, 0, draggedVariant);
    this.setState({ reorderedVariants: newOrder });
    // eslint-disable-next-line no-param-reassign
    draggedItem.index = targetIndex;
  });

  variantToProductLike = (variant) => ({
    ...variant,
    name: variant.name || variant.barcode,
    image_url: variant.images.reduce(
      (bestImage, image) =>
        (Math.abs(image.priority) < Math.abs(bestImage.priority)
          ? image
          : bestImage),
      { priority: Infinity }
    )?.url,
    prices: variant.stocks.reduce(
      ({ min_price: minPrice, max_price: maxPrice }, stock) => {
        const { sale } = getPrices(stock);
        return {
          min_price: Math.min(minPrice, sale),
          max_price: Math.max(maxPrice, sale),
        };
      },
      { min_price: Infinity, max_price: -Infinity }
    ),
  });

  startReorderMode = () => {
    const { collection } = this.state;
    const reorderedVariants = collection.variants.map(this.variantToProductLike);
    this.setState({
      reordering: true,
      reorderedVariants,
    });
  };

  endReorderMode = () => {
    this.setState({
      reordering: false,
      reorderedVariants: [],
    });
  };

  handleReorderSubmit = async (values) => {
    const { submit } = this.props;
    const { reorderedVariants } = this.state;
    const formValues = values.toJS();
    return submit(
      "ReorderCollectionVariants",
      urlUtils.getProperApiUrl("product/collection/element"),
      {
        ...formValues,
        variant_ids: reorderedVariants.map(({ id }) => id),
      },
      false
    );
  };

  getCollection = async ({
    name: searchName = "",
    start = 0,
    count = 0,
  } = {}) => {
    const { history, orgId, match } = this.props;
    const { collectionId, collectionName } = match.params;
    const result = { products: [], total: 0 };
    if (collectionId && orgId) {
      try {
        const collection = await this.productsRequestGroup.getCollection(
          collectionId
        );
        this.setState({
          collection,
          variantsSet: new Set(collection.variants.map(({ id }) => id)),
        });
        history.replace(
          `/collections/${collection.id}/${collection.p_name}/products`
        );
        result.total = collection.variants.length;
        result.products = collection.variants
          .filter(
            ({ name = "", description = "", barcode }) =>
              name
                ?.toLocaleLowerCase()
                ?.includes(searchName.toLocaleLowerCase())
              || description
                ?.toLocaleLowerCase()
                ?.includes(searchName.toLocaleLowerCase())
              || barcode === searchName
          )
          .slice(start, start + count)
          .map((variant) => ({
            ...this.variantToProductLike(variant),
            added: true,
          }));
      } catch {
        // history.replace("/pages/not-found");
      }
    } else {
      this.setState({ collection: { id: collectionId, name: collectionName } });
    }
    return result;
  };

  searchVariants = async ({ name = "", start = 0, count = 0 } = {}) => {
    const { orgId } = this.props;
    const { variantsSet } = this.state;
    this.productsRequestGroup.cancelAndReuse();
    if (orgId) {
      const { variants, total } = await this.productsRequestGroup.getVariants({
        org_id: orgId,
        name,
        start,
        count,
      });
      return {
        products: variants.map((variant) => ({
          ...this.variantToProductLike(variant),
          added: variantsSet.has(variant.id),
        })),
        total,
      };
    }
    return { products: [], total: 0 };
  };

  addRemoveVariant = (operation) => async (variantId) => {
    const { match } = this.props;
    const { collectionId } = match.params;
    const result = await this.postRequestGroup.postCollectionElement(
      operation,
      collectionId,
      [variantId]
    );
    await this.getCollection();
    return result;
  };

  backToCollection = () => {
    const { history } = this.props;
    const { collection } = this.state;
    history.replace(`/collections/${collection.id}/${collection.p_name}`);
  };

  render() {
    const { classes, match, orgId } = this.props;
    const { collectionId } = match.params;
    const { collection, reordering, reorderedVariants } = this.state;

    return reordering ? (
      <PaperForm
        form="ReorderCollectionVariants"
        title="Reorder Collection Products"
        description="drag product from handle to change its position"
        submitPristine
        hideReset
        updateUnregisteredFields
        initialValues={{
          product_id: collectionId,
          operation: "update",
        }}
        onSubmit={this.handleReorderSubmit}
        onFormCancelled={this.endReorderMode}
        onSubmitSuccess={this.endReorderMode}
      >
        <Field key="product_id" name="product_id" component={() => null} />
        <Field key="operation" name="operation" component={() => null} />
        <ReorderList
          key="collectionProducts"
          listId="collection product"
          items={reorderedVariants}
          onItemHover={this.onDragHover}
        />
      </PaperForm>
    ) : (
      <PapperBlock
        title="Manage Collection Products"
        whiteBg
        icon="ios-menu-outline"
        button={(
          <Button
            onClick={this.backToCollection}
            startIcon={<ArrowBackIosIcon />}
          >
            back
          </Button>
        )}
      >
        <div>
          <div className={classes.table}>
            {Boolean(orgId) && (
              <ProductsPicker
                key={collectionId}
                allProductsTitle="All Products"
                addedProductsTitle={(
                  <>
                    <span>
                      {collection.name}
                      {" "}
                      Variants
                    </span>
                    <Tooltip title="reorder" placement="bottom">
                      <IconButton onClick={this.startReorderMode} disabled={!(collection.variants?.length > 1)}>
                        <SwapVertIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                getAllProducts={this.searchVariants}
                getAddedProducts={this.getCollection}
                addProduct={this.addRemoveVariant("add")}
                removeProduct={this.addRemoveVariant("delete")}
              />
            )}
          </div>
        </div>
      </PapperBlock>
    );
  }
}

CollectionProducts.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ConnectedComponent = connect((state) => ({
  orgId: state.getIn(["users", "user", "info", "organization_id"]),
}),
dispatch => ({
  submit: getSubmitDispatcher(dispatch)
}))(CollectionProducts);

export default withStyles(styles)(ConnectedComponent);
