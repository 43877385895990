import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { reduxForm } from "redux-form/immutable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import { setConfirmNavigation } from "actions/UiActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormProgress from "./FormProgress";
import { initAction, clearAction } from "../../actions/ReduxFormActions";

const styles = theme => ({
  fieldsContainer: {
    position: "relative",
  },
  fieldsOverlay: {
    zIndex: 6,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000040",
    borderRadius: 3,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  spinner: {
    margin: "50%"
  },
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  progress: {
    marginBottom: theme.spacing(1),
    width: "80%",
    height: 10
  },
  cancelProgress: {
    backgroundColor: "#ed5e68",
    "&:hover": {
      backgroundColor: "red"
    }
  }
});

class ReduxForm extends Component {
  componentDidUpdate(prevProps) {
    const { blockNavigation } = this.props;
    const shouldBlock = this.getShouldBlockNAvigation();
    if (shouldBlock !== this.getShouldBlockNAvigation(prevProps)) {
      blockNavigation(shouldBlock);
    }
  }

  componentWillUnmount() {
    const { cancel, blockNavigation } = this.props;
    cancel();
    blockNavigation(false);
  }

  getShouldBlockNAvigation = (
    { blockOnDirty, dirty, blockOnSubmitting, submitting } = this.props
  ) => (blockOnDirty && dirty) || (blockOnSubmitting && submitting);

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      hideButtons,
      children,
      progress,
      cancel,
      onFormCancelled,
      hideReset,
      buttonsContainer: ButtonsContainer,
      submitPristine,
      submitText,
      resetText,
      cancelText,
      classes
    } = this.props;
    const childernArray = children ? Array.isArray(children) ? children : [children] : [];

    return (
      <form onSubmit={handleSubmit}>
        <div className={classes.fieldsContainer} key="fieldsContainer">
          {submitting && (
            <div className={classes.fieldsOverlay}>
              {progress ? (
                <div className={classes.progressWrapper}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    className={classes.progress}
                  />
                  <Button type="button" onClick={cancel} className={classes.cancelProgress}>
                    cancel
                  </Button>
                </div>
              ) : (
                <img src="./images/spinner.gif" alt="spinner" />
              )}
            </div>
          )}
          {childernArray.map((Field) => (
            <div key={Field.key}>{Field}</div>
          ))}
        </div>
        {!hideButtons && (
          <ButtonsContainer key="buttons">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={(pristine && !submitPristine) || submitting}
            >
              {submitText}
            </Button>
            {!hideReset && (
              <Button
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                {resetText}
              </Button>
            )}
            {onFormCancelled && (
              <Button
                type="button"
                onClick={() => {
                  if (
                    (pristine && !submitting)
                    || window.confirm(
                      "changes won't be saved\nAre you sure you want to cancel?"
                    )
                  ) {
                    cancel();
                    onFormCancelled();
                  }
                }}
              >
                {cancelText}
              </Button>
            )}
          </ButtonsContainer>
        )}
      </form>
    );
  }
}

ReduxForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired,
  hideButtons: PropTypes.bool,
  blockNavigation: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  onFormCancelled: PropTypes.func,
  hideReset: PropTypes.bool,
  buttonsContainer: PropTypes.node,
  /* eslint-disable react/no-unused-prop-types */
  blockOnDirty: PropTypes.bool,
  dirty: PropTypes.bool.isRequired,
  blockOnSubmitting: PropTypes.bool,
  /* eslint-enable react/no-unused-prop-types */
  submitPristine: PropTypes.bool,
  progress: PropTypes.number,
  submitText: PropTypes.string,
  resetText: PropTypes.string,
  cancelText: PropTypes.string,
};

ReduxForm.defaultProps = {
  onFormCancelled: null,
  hideButtons: false,
  blockOnDirty: true,
  blockOnSubmitting: true,
  hideReset: false,
  buttonsContainer: "div",
  submitPristine: false,
  cancel: () => {},
  progress: null,
  submitText: "Submit",
  resetText: "Reset",
  cancelText: "Cancel",
};

const StyledComponent = withStyles(styles)(ReduxForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  init: bindActionCreators(initAction, dispatch),
  clear: () => dispatch(clearAction),
  onSubmitSuccess: ({ message, result, ...rest }, _, props) => {
    if (message) dispatch({ type: ADD_NOTIFICATION, message });
    props.reset();
    ownProps.onSubmitSuccess(result, rest);
  },
  onSubmitFail: (_, __, submitError) => {
    submitError && dispatch({ type: ADD_NOTIFICATION, message: submitError.message || submitError });
    ownProps.onSubmitFail();
  },
  blockNavigation: bindActionCreators(setConfirmNavigation, dispatch)
});

const ReduxFormMapped = reduxForm({
  enableReinitialize: true
})(StyledComponent);

ReduxFormMapped.defaultProps = {
  cancel: () => {}
};

const FormInit = connect(
  (state, ownProps) => ({
    progress: state.getIn(["forms", ownProps.form, "progress"], null),
    cancel: state.getIn(["forms", ownProps.form, "cancel"])
  }),
  mapDispatchToProps
)(ReduxFormMapped);

FormInit.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func
};

FormInit.defaultProps = {
  onSubmitSuccess: () => {},
  onSubmitFail: () => {}
};

export default FormInit;
