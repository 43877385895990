import { paths } from "utils/url";

export default [
  {
    key: "dashboard",
    name: "Home",
    icon: "ios-home-outline",
    link: paths.dashboardPage,
  },
  {
    key: "category",
    name: "Category",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_category",
        name: "MANAGE CATEGORY",
        title: true
      },
      {
        key: "create_category",
        name: "Create Category",
        link: paths.createCategory,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_category",
        name: "Edit Categories",
        link: paths.categories,
        icon: "ios-list-box-outline"
      }
    ]
  },
  {
    key: "brands",
    name: "Brands",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_brands",
        name: "Manage Brands",
        title: true
      },
      {
        key: "create_brand",
        name: "Add New Brand",
        link: paths.createBrand,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_brands",
        name: "Edit Brands",
        link: paths.brands,
        icon: "ios-list-box-outline"
      }
    ]
  },
  {
    key: "collections",
    name: "Collections",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_collections",
        name: "Manage Collections",
        title: true
      },
      {
        key: "create_collection",
        name: "Add New Collection",
        link: paths.createCollection,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_collection",
        name: "Edit Collections",
        link: paths.collections,
        icon: "ios-list-box-outline"
      }
    ]
  },
  {
    key: "orders",
    name: "Orders",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_orders",
        name: "Manage Orders",
        link: paths.manageOrders,
        icon: "ios-list-box-outline",
      },
      {
        key: "return_requests",
        name: "Return Requests",
        link: paths.returnRequests,
        icon: "ios-list-box-outline",
      },
      {
        key: "find_return_request",
        name: "Find Return Request",
        link: paths.findReturnRequest,
        icon: "ios-list-box-outline",
      }
    ]
  },
  {
    key: "organization",
    name: "Organization",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_organization",
        name: "Manage organization",
        title: true
      },
      {
        key: "create_update_organization",
        name: "Create/Update Organization",
        link: paths.organization,
        icon: "ios-list-box-outline"
      },
      {
        key: "organization_info",
        name: "Business info",
        link: paths.organizationInfo,
        icon: "ios-list-box-outline"
      },
      {
        key: "organization_site_map",
        name: "Generate Site Map",
        link: paths.organizationSiteMap,
        icon: "ios-list-box-outline"
      },
      {
        key: "manage_domains",
        name: "Manage Domains",
        link: paths.manageDomains,
        icon: "ios-list-box-outline"
      }
    ]

  },
  {
    key: "products",
    name: "Products",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_products",
        name: "Manage Products",
        title: true
      },
      {
        key: "create_product_feature",
        name: "Add New Feature",
        link: paths.createProductFeature,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_product_features",
        name: "Edit Product Features",
        link: paths.productFeatures,
        icon: "ios-list-box-outline"
      },
      {
        key: "create_product",
        name: "Add New Product",
        link: paths.createProduct,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_product",
        name: "Edit Products",
        link: paths.products,
        icon: "ios-list-box-outline"
      },
      {
        key: "export_products",
        name: "Export Products",
        link: paths.exportProducts,
        icon: "ios-list-box-outline"
      },
      {
        key: "import_products",
        name: "Import Products",
        link: paths.importProducts,
        icon: "ios-list-box-outline"
      },
      {
        key: "products_images",
        name: "Import Products Images",
        link: paths.productsImages,
        icon: "ios-list-box-outline"
      },
      {
        key: "products_reviews",
        name: "Products Reviews",
        link: paths.reviews,
        icon: "ios-list-box-outline"
      },
    ]
  },
  {
    key: "promotions",
    name: "Promotions",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_promotions",
        name: "Manage Promotions",
        title: true
      },
      {
        key: "create_promotion",
        name: "Add New Promotions",
        link: paths.createPromotion,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_promotions",
        name: "Edit Promotions",
        link: paths.listPromotions,
        icon: "ios-list-box-outline"
      }
    ]
  },
  {
    key: "shops",
    name: "Shops",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_shops",
        name: "Manage Shops",
        title: true
      },
      {
        key: "create_shop",
        name: "Add New Shop",
        link: paths.createShop,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_shop",
        name: "Edit Shops",
        link: paths.shops,
        icon: "ios-list-box-outline"
      },
      {
        key: "setup_areas",
        name: "Setup Areas",
        link: paths.subAreas,
        icon: "ios-list-box-outline"
      }
    ]
  },
  {
    key: "tags",
    name: "Tags",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_tags",
        name: "Manage Tags",
        title: true
      },
      {
        key: "create_tag",
        name: "Add New Tag",
        link: paths.createTag,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_tags",
        name: "Edit Tags",
        link: paths.tags,
        icon: "ios-list-box-outline"
      },
      {
        key: "tags_tree",
        name: "Edit Tags tree",
        link: paths.tagsTree,
        icon: "ios-list-box-outline"
      }
    ]
  },
  {
    key: "theme",
    name: "Theme",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_theme",
        name: "Manage Theme",
        title: true
      },
      {
        key: "organization_themes",
        name: "Edit theme settings",
        link: paths.organizationThemes,
        icon: "ios-list-box-outline"
      },
      {
        key: "organization_image",
        name: "Upload images",
        link: paths.organizationImage,
        icon: "ios-list-box-outline"
      },
      {
        key: "themes_classes",
        name: "Themes Classes",
        link: paths.themesClasses,
        icon: "ios-list-box-outline"
      },
      {
        key: "organizations_classes",
        name: "Organizations Classes",
        link: paths.organizationsClasses,
        icon: "ios-list-box-outline"
      },
      {
        key: "create_theme",
        name: "Create Theme",
        link: paths.createTheme,
        icon: "ios-list-box-outline"
      },
      {
        key: "update_themes",
        name: "Edit Themes",
        link: paths.themes,
        icon: "ios-list-box-outline"
      }
    ]
  },
  {
    key: "Points",
    name: "Points",
    icon: "ios-list-box-outline",
    child: [
      {
        key: "configuration",
        name: "configuration",
        link: paths.pointsConfiguration,
      },
      {
        key: "control_points",
        name: "control points",
        link: paths.controlPoints,
      },
    ],
  },
  {
    key: "users",
    name: "Users",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_users",
        name: "Manage Users",
        title: true
      },
      {
        key: "create_user",
        name: "Add New Employee",
        link: paths.createUser,
        icon: "ios-list-box-outline"
      },
      {
        key: "manage_employees",
        name: "Manage Employees",
        link: paths.employees,
        icon: "ios-list-box-outline"
      },
      {
        key: "manage_customers",
        name: "Manage Customers",
        link: paths.customers,
        icon: "ios-list-box-outline"
      },
      {
        key: "subscribed_users",
        name: "Subscribed Users",
        link: paths.subscribedUsers,
        icon: "ios-list-box-outline",
      }
    ]
  },
  {
    key: "video_chat",
    name: "Video Chat",
    icon: "ios-paper-outline",
    child: [
      {
        key: "manage_video_chat",
        name: "Manage Video Chat",
        title: true
      },
      {
        key: "video_chats",
        name: "Video Chats",
        link: paths.videoChat,
        icon: "ios-list-box-outline"
      }
    ]
  },
  // {
  //   key: "auth",
  //   name: "Auth Page",
  //   icon: "ios-contact-outline",
  //   child: [
  //     {
  //       key: "auth_page",
  //       name: "User Authentication",
  //       title: true
  //     },
  //     {
  //       key: "login",
  //       name: "Login",
  //       link: "/login",
  //       icon: "ios-person-outline"
  //     },
  //     {
  //       key: "register",
  //       name: "Register",
  //       link: "/register",
  //       icon: "ios-key-outline"
  //     },
  //     {
  //       key: "reset",
  //       name: "Reset Password",
  //       link: "/reset-password",
  //       icon: "ios-undo-outline"
  //     }
  //   ]
  // },
  // {
  //   key: "errors",
  //   name: "Errors",
  //   icon: "ios-paw-outline",
  //   child: [
  //     {
  //       key: "errors_page",
  //       name: "Errors Pages",
  //       title: true
  //     },
  //     {
  //       key: "not_found_page",
  //       name: "Not Found Page",
  //       link: "/pages/not-found",
  //       icon: "ios-warning-outline"
  //     },
  //     {
  //       key: "error_page",
  //       name: "Error Page",
  //       link: "/pages/error",
  //       icon: "ios-warning-outline"
  //     }
  //   ]
  // },
  // {
  //   key: "menu_levels",
  //   name: "Menu Levels",
  //   multilevel: true,
  //   icon: "ios-menu-outline",
  //   child: [
  //     {
  //       key: "level_1",
  //       name: "Level 1",
  //       link: "/#"
  //     },
  //     {
  //       key: "level_2",
  //       keyParent: "menu_levels",
  //       name: "Level 2",
  //       child: [
  //         {
  //           key: "sub_menu_1",
  //           name: "Sub Menu 1",
  //           link: "/#"
  //         },
  //         {
  //           key: "sub_menu_2",
  //           name: "Sub Menu 2",
  //           link: "/#"
  //         }
  //       ]
  //     }
  //   ]
  // }
];
