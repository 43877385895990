import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TableIcon from "@material-ui/icons/Apps";
import styles from "components/Tables/tableStyle-jss";

function EmptyData(props) {
  const { classes, children } = props;
  return (
    <div className={classes.nodata}>
      <TableIcon />
      {children}
    </div>
  );
}

EmptyData.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

EmptyData.defaultProps = {
  children: "No Data"
};

export default withStyles(styles)(EmptyData);
