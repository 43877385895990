import React from "react";
import { PropTypes } from "prop-types";
import { Switch, Route } from "react-router-dom";
import ExportProducts from "containers/Pages/Standalone/ExportProducts";
import CollectionProducts from "containers/Pages/Table/CollectionProducts";
import ProtectedRoute from "utils/ProtectedRoute";
import { paths } from "utils/url";
import Dashboard from "../Templates/Dashboard";
import {
  // Parent,
  DashboardPage,
  // BlankPage,
  Organization,
  // Table,
  // Maintenance,
  // ComingSoon,
  // Error,
  NotFound,
  CreateBrand,
  OrganizationImage,
  CreateShop,
  CreateCategory,
  UpdateCategory,
  OrganizationInfo,
  CreateUser,
  UpdateUser,
  Employees,
  Customers,
  SubscribedUsers,
  ImportProducts,
  ManageOrders,
  SingleOrder,
  CreateTag,
  UpdateTag,
  Tags,
  Brands,
  Shops,
  UpdateBrand,
  UpdateShop,
  ProductsImages,
  CreateProductFeature,
  TagsTree,
  ShopSections,
  Shop360Bulk,
  Shop360Editor,
  CreateCollection,
  UpdateCollection,
  TagProducts,
  Collections,
  // Products360,
  ThemesClasses,
  CreateTheme,
  OrganizationThemes,
  CreatePromotion,
  UpdatePromotion,
  ListPromotions,
  Reviews,
  CreateProduct,
  UpdateProduct,
  Products,
  ReturnRequests,
  SingleReturnRequest,
  FindReturnRequest,
  UpdateTheme,
  OrganizationClasses,
  Themes,
  SubAreas,
  GenerateSiteMap,
  RelatedProducts,
  RelatedCollections,
  MetaOrder,
  ProductFeatures,
  UpdateProductFeature,
  VideoChat,
  OrganizationDomains,
  ProductVariants,
  Categories,
  UpdateVariant,
  PointsConfiguration,
  ControlPoints,
  CreateControlPoint,
  UpdateControlPoint
} from "../pageListAsync";

class Application extends React.Component {
  render() {
    const { changeMode, history, user } = this.props;
    return (
      <Dashboard history={history} changeMode={changeMode} user={user}>
        <Switch>
          {/* <ProtectedRoute exact path="/" component={BlankPage} /> */}
          <ProtectedRoute exact path={paths.dashboardPage} component={DashboardPage} />
          {/* Nasnav Admin only */}
          <ProtectedRoute exact path={paths.organization} component={Organization} />
          <ProtectedRoute exact path={paths.manageDomains} component={OrganizationDomains} />
          <ProtectedRoute exact path={paths.createCategory} component={CreateCategory} />
          <ProtectedRoute exact path={paths.updateCategory} component={UpdateCategory} />
          {/* Org. Admin and Managers only */}
          <ProtectedRoute exact path={paths.createBrand} component={CreateBrand} />
          <ProtectedRoute exact path={paths.createShop} component={CreateShop} />
          <ProtectedRoute exact path={paths.shopSections} component={ShopSections} />
          <ProtectedRoute exact path={paths.shop360Bulk} component={Shop360Bulk} />
          <ProtectedRoute exact path={paths.shop360Editor} component={Shop360Editor} />
          <ProtectedRoute exact path={paths.updateShop} component={UpdateShop} />
          <ProtectedRoute exact path={paths.shops} component={Shops} />
          <ProtectedRoute exact path={paths.subAreas} component={SubAreas} />
          <ProtectedRoute exact path={paths.organizationImage} component={OrganizationImage} />
          <ProtectedRoute exact path={paths.organizationInfo} component={OrganizationInfo} />
          <ProtectedRoute exact path={paths.organizationSiteMap} component={GenerateSiteMap} />
          <ProtectedRoute exact path={paths.createUser} component={CreateUser} />
          <ProtectedRoute exact path={paths.updateUser} component={UpdateUser} />
          <ProtectedRoute exact path={paths.employees} component={Employees} />
          <ProtectedRoute exact path={paths.subscribedUsers} component={SubscribedUsers} />
          <ProtectedRoute exact path={paths.importProducts} component={ImportProducts} />
          <ProtectedRoute exact path={paths.exportProducts} component={ExportProducts} />
          <ProtectedRoute exact path={paths.manageOrders} component={ManageOrders} />
          <ProtectedRoute exact path={paths.singleOrder} component={SingleOrder} />
          <ProtectedRoute exact path={paths.singleMetaOrder} component={MetaOrder} />
          <ProtectedRoute exact path={paths.findReturnRequest} component={FindReturnRequest} />
          <ProtectedRoute exact path={paths.returnRequests} component={ReturnRequests} />
          <ProtectedRoute exact path={paths.singleReturnRequest} component={SingleReturnRequest} />
          <ProtectedRoute exact path={paths.createProductFeature} component={CreateProductFeature} />
          <ProtectedRoute exact path={paths.updateProductFeature} component={UpdateProductFeature} />
          <ProtectedRoute exact path={paths.productFeatures} component={ProductFeatures} />
          <ProtectedRoute exact path={paths.createTag} component={CreateTag} />
          <ProtectedRoute exact path={paths.updateTag} component={UpdateTag} />
          <ProtectedRoute exact path={paths.tagProducts} component={TagProducts} />
          <ProtectedRoute exact path={paths.tags} component={Tags} />
          <ProtectedRoute exact path={paths.createProduct} component={CreateProduct} />
          <ProtectedRoute exact path={paths.updateProduct} component={UpdateProduct} />
          <ProtectedRoute exact path={paths.relatedProducts} component={RelatedProducts} />
          <ProtectedRoute exact path={paths.products} component={Products} />
          <ProtectedRoute exact path={paths.createCollection} component={CreateCollection} />
          <ProtectedRoute exact path={paths.updateCollection} component={UpdateCollection} />
          <ProtectedRoute exact path={paths.collectionProducts} component={CollectionProducts} />
          <ProtectedRoute exact path={paths.relatedCollections} component={RelatedCollections} />
          <ProtectedRoute exact path={paths.collections} component={Collections} />
          <ProtectedRoute exact path={paths.updateBrand} component={UpdateBrand} />
          <ProtectedRoute exact path={paths.brands} component={Brands} />
          <ProtectedRoute exact path={paths.tagsTree} component={TagsTree} />
          <ProtectedRoute exact path={paths.productsImages} component={ProductsImages} />
          {/* <ProtectedRoute path="/products-360" component={Products360} /> */}
          <ProtectedRoute exact path={paths.themesClasses} component={ThemesClasses} />
          <ProtectedRoute exact path={paths.createTheme} component={CreateTheme} />
          <ProtectedRoute exact path={paths.themes} component={Themes} />
          <ProtectedRoute exact path={paths.UpdateTheme} component={UpdateTheme} />
          <ProtectedRoute exact path={paths.organizationsClasses} component={OrganizationClasses} />
          <ProtectedRoute exact path={paths.organizationThemes} component={OrganizationThemes} />
          <ProtectedRoute exact path={paths.createPromotion} component={CreatePromotion} />
          <ProtectedRoute exact path={paths.updatePromotion} component={UpdatePromotion} />
          <ProtectedRoute exact path={paths.listPromotions} component={ListPromotions} />
          <ProtectedRoute exact path={paths.reviews} component={Reviews} />
          <ProtectedRoute exact path={paths.customers} component={Customers} />
          <ProtectedRoute exact path={paths.categories} component={Categories} />
          <ProtectedRoute exact path={paths.videoChat} component={VideoChat} />
          <ProtectedRoute exact path={paths.productVariants} component={ProductVariants} />
          <ProtectedRoute exact path={paths.updateVariant} component={UpdateVariant} />
          {/* <ProtectedRoute path="/table" component={Table} />
          <ProtectedRoute path="/page-list" component={Parent} /> */}
          {/* <Route path="/pages/maintenance" component={Maintenance} />
          <Route path="/pages/coming-soon" component={ComingSoon} />
          <Route path="/pages/not-found" component={NotFound} />
          <Route path="/pages/error" component={Error} /> */}
          <ProtectedRoute exact path={paths.pointsConfiguration} component={PointsConfiguration} />
          <ProtectedRoute exact path={paths.controlPoints} component={ControlPoints} />
          <ProtectedRoute exact path={paths.createControlPoints} component={CreateControlPoint} />
          <ProtectedRoute exact path={paths.updateControlPoint} component={UpdateControlPoint} />
          <Route component={NotFound} />
        </Switch>
      </Dashboard>
    );
  }
}

Application.propTypes = {
  changeMode: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default Application;
