import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import Loading from "components/Loading";
import NotFound from "containers/NotFound/NotFound";
import { checkPermissions } from "./users";

function ProtectedRoute({
  exact,
  path,
  component: Component,
  render,
  loggedIn,
  userInfo,
  userRoles,
  skipRoles,
  userOrgId,
  orgDataOrgId,
}) {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (!loggedIn) {
          return <Redirect to="/login" />;
        }
        if (!skipRoles && (!userRoles || Number(userOrgId) !== Number(orgDataOrgId))) {
          return <Loading />;
        }
        if (!skipRoles && userRoles && !checkPermissions(path, userRoles)) {
          return <NotFound />;
        }
        const newProps = { ...props, user: userInfo.toJS() };
        const ret = (render && render(newProps)) || <Component {...newProps} />;
        return ret;
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType,
  render: PropTypes.func,
  loggedIn: PropTypes.bool.isRequired,
  userInfo: PropTypes.object,
  userRoles: PropTypes.object,
  skipRoles: PropTypes.bool,
  userOrgId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  orgDataOrgId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
};

ProtectedRoute.defaultProps = {
  exact: false,
  component: null,
  render: null,
  userInfo: new Map({ name: "Welcome" }),
  userRoles: undefined,
  skipRoles: false,
  userOrgId: undefined,
  orgDataOrgId: undefined,
};

const mapStateToProps = (state) => ({
  loggedIn: state.getIn(["users", "user", "loggedIn"]),
  userInfo: state.getIn(["users", "user", "info"]),
  userRoles: state.getIn(["users", "user", "roles"]),
  userOrgId: state.getIn(["users", "user", "info", "organization_id"]),
  orgDataOrgId: state.getIn([
    "cachedData",
    "cache",
    "orgData",
    "value",
    "id",
  ]),
});

export default connect(mapStateToProps)(ProtectedRoute);
