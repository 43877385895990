import React from "react";
import Portal from "@material-ui/core/Portal";
import { connect } from "react-redux";
import { Notification } from "components";
import { REMOVE_NOTIFICATION } from "../../actions/actionConstants";

const NotificationsProvider = ({ notifications, remove }) => (
  notifications.entrySeq().map(([key, value]) => (
    <Portal container={document.getElementById("notifications-provider-wrapper")}>
      <Notification
        key={key}
        open
        message={value}
        autoHideDuration={3000}
        onClose={() => remove(key)}
      />
    </Portal>
  ))
);

export default connect(
  state => ({
    notifications: state.getIn(["notifications", "notifications"])
  }),
  dispatch => ({
    remove: key => dispatch({ type: REMOVE_NOTIFICATION, key })
  })
)(NotificationsProvider);
