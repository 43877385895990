function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}

const toBackendDateString = date => (
  date.getUTCFullYear()
  + "-"
  + pad(date.getUTCMonth() + 1)
  + "-"
  + pad(date.getUTCDate())
  + ":"
  + pad(date.getUTCHours())
  + ":"
  + pad(date.getUTCMinutes())
  + ":"
  + pad(date.getUTCSeconds())
);

const toDate = (value, fallback) => {
  if (value instanceof Date) {
    return value;
  }
  if (Number.isFinite(value)) {
    const date = new Date();
    date.setTime(value);
    return date;
  }
  return fallback;
};

const toISOStringWithoutMillis = (date) =>
  date
    .toISOString()
    .split(/\.[0-9]{3}/, 2)
    .join("");

export default {
  toBackendDateString,
  toDate,
  toISOStringWithoutMillis
};
