import React from "react";
import { Helmet } from "react-helmet";
import brand from "api/dummy/brand";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ForgotPasswordForm } from "components";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loading from "components/Loading";
import styles from "../../../components/Forms/user-jss";
import urlUtils from "../../../utils/url";

const errorsMap = {
  UXACTVX0007: "Email doesn't exist",
  fallback: "Something went wrong. Please try again later."
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  submitForm = async (values) => {
    try {
      this.setState({ loading: true });
      const { email } = values.toJS();
      const res = await fetch(urlUtils.getProperApiUrl(`/user/recover?employee=true&email=${email}`), { method: "GET" });

      if (res.status !== 200) {
        const data = await res.json();
        throw new Error(errorsMap[data?.error ?? "fallback"]);
      }

      return "Reset password link has been sent to your email. Please check your email.";
    } catch (ex) {
      throw new Error(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const title = brand.name + " - Reset Password";
    const description = brand.desc;
    const { classes, loggedIn, orgId } = this.props;
    if (loggedIn) {
      if (orgId) return <Redirect to="/" />;
      return <Loading />;
    }
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <div className={classes.userFormWrap}>
            <ForgotPasswordForm onSubmit={(values) => this.submitForm(values)} loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  orgId: PropTypes.number
};

ForgotPassword.defaultProps = {
  orgId: undefined
};

const mapStateToProps = state => ({
  loggedIn: state.getIn(["users", "user", "loggedIn"]),
  orgId: state.getIn(["users", "user", "info", "organization_id"]),
});

const ConnectedComponent = connect(mapStateToProps)(ForgotPassword);

export default withStyles(styles)(ConnectedComponent);
